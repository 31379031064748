import React, { useEffect, useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { PRIMARY_DARK } from "../../Constants/Colors";

export default function TimeContainer({
  index = 0,
  time = "7:00 AM - 8:00 AM",
  value,
  onSelect = () => {},
}) {
  const [isSelected, setIsSelected] = useState(value === index ? true : false);

  useEffect(() => {
    setIsSelected(value === index);
  }, [value]);

  return (
    <div
      className="time_main"
      style={{ backgroundColor: isSelected ? PRIMARY_DARK : "" }}
      key={index}
      onClick={() => {
        setIsSelected(!isSelected);
        onSelect(index);
      }}
    >
      {isSelected ? (
        <span className="time_main_icon">
          <IoCheckmarkCircleOutline fill="white" size={22} />
        </span>
      ) : null}
      <span className="time_main_time">{time}</span>
    </div>
  );
}

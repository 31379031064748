import React from "react";
import Slider from "react-slick";
import service_1 from "../../Assets/service-img-1.jpg";
import service_2 from "../../Assets/service-img-2.jpg";
import service_3 from "../../Assets/service-img-3.jpg";
import Cards from "./Cards";
import hrbar from "../../Assets/hrBar.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BASE_URL } from "../../Constants/constantVariable";

function CircleCardSection({ cardsData = [] }) {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="w-full hidden md:block">
        <div className="w-full bg-white flex flex-wrap pt-20 pb-10 ">
          <div className="w-full flex flex-col justify-center pb-4">
            <h1 className="text-4xl text-[#4b5b68] font-extrabold w-full text-center">
              Services
            </h1>
            <p className="text-xs text-[#677D96] w-[90%] md:w-[70%] m-auto text-center py-4">
              Explore our comprehensive and professional cleaning solutions
              tailored to your specific needs. As a dedicated cleaning partner
              in Perth, we are committed to delivering top-notch services that
              exceed expectations and promote a cleaner and healthier
              environment.
            </p>
            <div className="w-full flex justify-center">
              <img src={hrbar} alt="" />
            </div>
          </div>
          <div className="flex justify-center flex-wrap">
            {" "}
            {cardsData.map((item, index) => (
              <Cards
                key={index}
                name={item.heading}
                img={BASE_URL + item.image}
                content={item.description}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full bg-white pt-20 pb-10 block md:hidden">
        <div className="w-full flex flex-col justify-center pb-4">
          <h1 className="text-4xl text-[#4b5b68] font-extrabold w-full text-center">
            Services
          </h1>
          <p className="text-xs text-[#677D96] w-[90%] md:w-[70%] m-auto text-center py-4">
            Explore our comprehensive and professional cleaning solutions
            tailored to your specific needs. As a dedicated cleaning partner in
            Perth, we are committed to delivering top-notch services that exceed
            expectations and promote a cleaner and healthier environment.
          </p>
          <div className="w-full flex justify-center">
            <img src={hrbar} alt="" />
          </div>
        </div>
        <Slider {...settings}>
          {cardsData.map((item, index) => (
            <Cards
              key={index}
              name={item.heading}
              img={BASE_URL + item.image}
              content={item.description}
            />
          ))}
        </Slider>
      </div>
    </>
  );
}

export default CircleCardSection;

import React from "react";
import "./style.css";
import { RxCrossCircled } from "react-icons/rx";
import { FaFilePdf } from "react-icons/fa";

export default function UploadFile({
  label,
  file,
  onSelect = () => {},
  onRemove = () => {},
  error = "",
}) {
  return (
    <div className="file-input" style={{ position: "relative" }}>
      <div className="input__box__label" style={{ marginTop: "40px" }}>
        {label}
      </div>
      <div
        className={
          file
            ? "Add_Client_Image_container file"
            : "Add_Client_Image_container"
        }
        style={{ border: error ? "1px solid red" : "" }}
      >
        <label
          className="changeimage_label"
          style={{ paddingTop: file ? "8px" : "" }}
        >
          {file ? (
            <>
              <div className="file_exist">
                <span>
                  <FaFilePdf size={22} />
                </span>
                <span>{file.name}</span>

                {/* <input
                  type="file"
                  className="formuploadimage_input"
                  accept=".pdf"
                  onChange={(e) => {
                    onSelect(e.target.files[0]);
                  }}
                  onClick={(event) => (event.currentTarget.value = null)}
                /> */}
              </div>
            </>
          ) : (
            <div className="AddimageBtn">
              <span className="cam_Image_upload">Browse file</span>
              <div>
                <input
                  type="file"
                  className="formuploadimage_input"
                  accept=".pdf"
                  onChange={(e) => {
                    onSelect(e.target.files[0]);
                  }}
                  onClick={(event) => (event.currentTarget.value = null)}
                />
              </div>
            </div>
          )}
        </label>
        <div
          style={{ display: file ? "" : "none" }}
          className="images_cross"
          onClick={() => onRemove()}
        >
          <RxCrossCircled size={30} />
        </div>
      </div>
      {error && <div className="thumbnail_err">{error}</div>}
    </div>
  );
}

import React, { useEffect } from "react";
import "./style.css";
import "animate.css";
import { useNavigate } from "react-router-dom";

export default function StripeSuccessPage() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);
  return (
    <div className="payment_success">
      <span className="animate__animated animate__slideInUp">
        <p className="congras_text">Congratulations 🎉!</p>
        Payment successfull. Your booking is confirmed 👍.
      </span>
    </div>
  );
}

import React from "react";
import chooseUsBg from "../../Assets/reson_to_choose_use.jpg";
import { ImLeaf } from "react-icons/im";

function ReasonToChosseUs() {
  return (
    <div className="bg-white p-10">
      <div className="flex flex-col-reverse md:flex-row  ">
        <div className="md:w-1/2 w-full py-3 md:py-5 flex justify-center items-center">
          <img src={chooseUsBg} alt="" />
        </div>
        <div className="px-2 md:w-1/2 w-full py-3 md:py-5">
          <div className="text-2xl md:text-4xl text-[#4b5b68]">
          Why AWS  <span className="text-[#2fbf66]">Cleaning Services</span>
          </div>
          <div className="text-[#677D8F] text-sm py-7">
          At AWS Cleaning Services, we understand the importance of a clean environment, whether it's your home or workplace. Here's why you should choose us :
          </div>
          <div className="flex gap-4">
            <div>
              <ImLeaf className="text-[#2fbf66] mt-2" />
            </div>
            <div>
              <div className="text-xl font-bold text-[#4b5b68]">
                Professional Team
              </div>
              <div className="text-[#677D8F] text-sm pt-6 pb-7">
              Our cleaners are trained, experienced, and dedicated to delivering high-quality service.

              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <ImLeaf className="text-[#2fbf66] mt-2" />
            </div>
            <div>
              <div className="text-xl font-bold text-[#4b5b68]">
              Customized Solutions
              </div>
              <div className="text-[#677D8F] text-sm pt-6 pb-7">
              We tailor our services to meet your specific needs, ensuring optimal results.

              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <ImLeaf className="text-[#2fbf66] mt-2" />
            </div>
            <div>
              <div className="text-xl font-bold text-[#4b5b68]">
              Eco-Friendly Practices
              </div>
              <div className="text-[#677D8F] text-sm pt-6 pb-7">
              We use environmentally friendly products and methods to protect your health and the environment.

              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <ImLeaf className="text-[#2fbf66] mt-2" />
            </div>
            <div>
              <div className="text-xl font-bold text-[#4b5b68]">
              Reliable Service
              </div>
              <div className="text-[#677D8F] text-sm pt-6 pb-7">
               We pride ourselves on being dependable and consistent, providing cleaning services you can trust.

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReasonToChosseUs;

import React from "react";
import "./modal.css";
import Modal from "react-modal";
import "animate.css";
import { RxCross2 } from "react-icons/rx";
import { BLACK_SHADY, HEADINGS_LIGHT } from "../../Constants/Colors";

export default function TermsAndConditionsModal({
  isVisible,
  setIsVisible = () => {},
}) {
  return (
    <Modal
      isOpen={isVisible}
      className="modal animate__animated animate__zoomIn"
      style={{ display: "flex" }}
    >
      <div className={`modal_container termsCondtions`}>
        <>
          <div
            className="icon_cross"
            onClick={(e) => {
              setIsVisible(false);
            }}
          >
            <RxCross2 color={BLACK_SHADY} size={"20"} />
          </div>

          <strong style={{ fontSize: "1.2rem", color: HEADINGS_LIGHT }}>
            Payment Terms and Conditions
          </strong>
          <div className="modal_terms_list">
            <ol>
              <li>
                In case of order cancellation within 7 hours no payment will be
                deducted except the tax. adf adfdhin 7 hours no payment will be
                deducted except the tax. adf adfdhin 7 hours no payment will be
                deducted except the tax. adf adfd
              </li>
              <li>
                After 7 hours of cancellation of order 20% payment will be
                deducted on refund.
              </li>
            </ol>
          </div>
        </>
      </div>
    </Modal>
  );
}

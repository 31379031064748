import React, { useEffect, useState } from "react";
import "./payment.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Enums } from "../../Constants/Enums";
import { IoMdArrowBack } from "react-icons/io";
import { Lists } from "../../Constants/Lists";
import moment from "moment";
import { postOrder } from "../../Api/orderDetails";
import {
  ErrorCode,
  STRIPE_PUBLISH_KEY,
} from "../../Constants/constantVariable";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getIntent } from "../../Api/payment";
import CheckoutForm from "./CheckoutForm";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import TermsAndConditionsModal from "../../Componants/modal/TermsAndConditionsModal";
import { ToastContainer, toast } from "react-toastify";

export default function Payment({ dataState, onBackClick = () => {} }) {
  const location = useLocation();
  // const data = location?.state || dataState;
  const data = dataState;

  const navigate = useNavigate();
  const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);
  const [isLoading, setIsLoading] = useState(false);
  const [tax_amount] = useState(parseFloat(data?.net_total / 10).toFixed(2));
  const [clientSeceret, setClientSeceret] = useState("");
  const [ismodalOpen, setIsmodalOpen] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  let [newdiscountedAmount, setNewDiscountedAmount] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    calculateDiscount();
    payClick();
  }, []);

  function handleBackClick() {
    onBackClick();
    // navigate("/quotation", { state: data });
  }

  function calculateDiscount() {
    debugger;
    let discount = 0;
    if (data.cleaning_type.value === Enums.cleaning_type.deep) {
      discount += (data?.stateTotalPrice * data?.stateDiscount) / 100;
      discount += (data.othersTotalPrice * data?.otherDiscount) / 100;
    }
    // if (data.other_cleaning_type.value === Enums.cleaning_type.deep) {
    //   discount += (data.othersTotalPrice * data?.otherDiscount) / 100;
    // }
    setDiscountAmount(discount);
    setNewDiscountedAmount(
      (newdiscountedAmount = (
        parseFloat(data?.net_total) - parseFloat(discount)
      ).toFixed(2))
    );
  }

  function payClick() {
    debugger;
    let getSecretKeyObj = {
      // amount: parseFloat(data?.net_total) + parseFloat(tax_amount),
      amount: newdiscountedAmount,
      currency: Enums.subscription_currency.dollar,
    };
    setIsLoading(true);
    getIntent(getSecretKeyObj)
      .then(({ data }) => {
        debugger;
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setClientSeceret(data.result);
        }
      })
      .catch((err) => {
        console.log("err in getting client secret");
        setIsLoading(false);
      });
  }

  function sendData() {
    // data.amount = parseFloat(data?.net_total) + parseFloat(tax_amount);
    data.amount = newdiscountedAmount;
    setIsLoading(true);
    postOrder(data)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          navigate("/booking-completion");
        } else if (data.error_code == ErrorCode.failed) {
          toast.warn("Oops! Some error occured");
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Network Error");
      });
  }

  return (
    <>
      <ToastContainer
        autoClose={1000}
        position="bottom-right"
        hideProgressBar
      />
      <article className="">
        <TermsAndConditionsModal
          isVisible={ismodalOpen}
          setIsVisible={setIsmodalOpen}
        />

        <div className="about_main payment">
          <p className="about_content payment">Payment</p>
        </div>
        <div className="payment_main_container">
          <div className="back_arrow" onClick={handleBackClick}>
            <IoMdArrowBack size={35} />
          </div>
          <h3>
            Your Booking Details
            <span
              className="total_view"
              style={{ display: "flex", gap: "5px" }}
            >
              Total:{"$ "}
              <span
                className={discountAmount > 0 ? "text_line_through" : ""}
                style={{ display: "flex" }}
              >
                {parseFloat(data?.net_total)}
              </span>
              {discountAmount > 0 && (
                <span className="discount_amount" style={{ display: "flex" }}>
                  {newdiscountedAmount}
                </span>
              )}
            </span>
            {/* when tax (GST) will be applied in the view then uncomment thiss  */}
            {/* <span className="total_view">
              Total: ${data?.net_total} + ${tax_amount} (GST) ={" "}
              <span className={discountAmount > 0 ? "text_line_through" : ""}>
                {parseFloat(data?.net_total) + parseFloat(tax_amount)}
              </span>
              {discountAmount > 0 && (
                <span className="discount_amount">
                  {(
                    parseFloat(data?.net_total) +
                    parseFloat(tax_amount) -
                    parseFloat(discountAmount)
                  ).toFixed(2)}
                </span>
              )}
            </span> */}
            {/* <div className="total_small_view">
              <div className="total_small_view_price">${data?.net_total}</div>
              <div className="total_small_view_gst">${tax_amount} (GST) +</div>
              <div
                className={discountAmount > 0 && "total_small_view_total_price"}
              >
                ${parseFloat(data?.net_total) + parseFloat(tax_amount)}
                {discountAmount <= 0 && ` (Total)`}
              </div>
              {discountAmount > 0 && (
                <p className="discount_amount_small_view">
                  $
                  {(
                    parseFloat(data?.net_total) +
                    parseFloat(tax_amount) -
                    parseFloat(discountAmount)
                  ).toFixed(2)}{" "}
                  (Total)
                </p>
              )}
            </div> */}
          </h3>

          <div className="booking_details">
            <div className="booking_data">
              <span className="booking_data_question">Cleaning type:</span>
              <span className="booking_data_selection">
                {data?.cleaning_type?.label}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">No. of storey:</span>
              <span className="booking_data_selection">
                {data?.storey?.label}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">No. of bedroom:</span>
              <span className="booking_data_selection">
                {data?.bedroom?.label}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">No. of bathroom:</span>
              <span className="booking_data_selection">
                {data?.bathroom?.label}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">Date of booking:</span>
              <span className="booking_data_selection">
                {data?.date_of_booking
                  ? moment(data?.date_of_booking).format("YYYY-MM-DD")
                  : "Not selected"}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">
                Time of cleaner arrival:
              </span>
              <span className="booking_data_selection">
                {data?.time_of_booking
                  ? Lists?.timeSlots[data?.time_of_booking.value - 1]?.label
                  : "Not selected"}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">Will you be home? </span>
              <span className="booking_data_selection">
                {data?.is_home === Enums.is_home.yes
                  ? " Yes, I'll be home"
                  : " No, I will leave key out"}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">Property type:</span>
              <span className="booking_data_selection">
                {data?.property_type === Enums.property_type.un_furnished
                  ? " Un-Furnished"
                  : " Furnished"}
              </span>
            </div>
            <div className="booking_data">
              <span className="booking_data_question">Power availability:</span>
              <span className="booking_data_selection">
                {data?.is_power_available === Enums.is_power_available.yes
                  ? " Yes, Power is available"
                  : " No, Power is not available"}
              </span>
            </div>
          </div>

          <div className="extras_booking_details_main">
            <h4>Extras</h4>
            <div className="booking_details">
              {data?.balcony && (
                <div className="booking_data">
                  <span className="booking_data_question">
                    Additional Balcony to clean:
                  </span>
                  <span className="booking_data_selection">
                    {data?.balcony?.label}
                  </span>
                </div>
              )}

              {data?.blind ? (
                <div className="booking_data">
                  <span className="booking_data_question">
                    No. of blinds to clean:
                  </span>
                  <span className="booking_data_selection">
                    {data?.blind?.label}
                  </span>
                </div>
              ) : null}

              {/* {data?.carpet ? (
              <div className="booking_data">
                <span className="booking_data_question">
                  No. of rooms for carpet Steam Clean:
                </span>
                <span className="booking_data_selection">
                  {data?.carpet?.label}
                </span>
              </div>
            ) : null} */}

              {data?.wall ? (
                <div className="booking_data">
                  <span className="booking_data_question">
                    No. of rooms for wall wash:
                  </span>
                  <span className="booking_data_selection">
                    {data?.wall?.label}
                  </span>
                </div>
              ) : null}

              {data?.window ? (
                <div className="booking_data">
                  <span className="booking_data_question">
                    No. of Outside windows to clean:
                  </span>
                  <span className="booking_data_selection">
                    {data?.window?.label}
                  </span>
                </div>
              ) : null}

              {data?.misc ? (
                <div className="booking_data">
                  <span className="booking_data_question">
                    miscellaneous service (by price):
                  </span>
                  <span className="booking_data_selection">
                    {data?.misc?.label}
                  </span>
                </div>
              ) : null}
              {/* singles slections */}

              <div className="booking_data">
                <span className="booking_data_question">Garage Cleaning:</span>
                <span className="booking_data_selection">
                  {data?.garage == 1 ? " Included" : " Not included"}
                </span>
              </div>
              <div className="booking_data">
                <span className="booking_data_question">Fridge cleaning:</span>
                <span className="booking_data_selection">
                  {data?.fridge == 1 ? " Included" : " Not included"}
                </span>
              </div>
              <div className="booking_data">
                <span className="booking_data_question">
                  Double Fridge cleaning:
                </span>
                <span className="booking_data_selection">
                  {data?.double_fridge == 1 ? " Included" : " Not included"}
                </span>
              </div>
              <div className="booking_data">
                <span className="booking_data_question">Pressure washing:</span>
                <span className="booking_data_selection">
                  {data?.pressure_washing == 1 ? " Included" : " Not included"}
                </span>
              </div>
              {/* <div className="booking_data">
              <span className="booking_data_question">Travel Fee:</span>
              <span className="booking_data_selection">
                {data?.travel_fee == 1 ? " Included" : " Not included"}
              </span>
            </div> */}
              <div className="booking_data">
                <span className="booking_data_question">
                  Living area cleaning:
                </span>
                <span className="booking_data_selection">
                  {data?.living_area == 1 ? " Included" : " Not included"}
                </span>
              </div>
              {/* <div className="booking_data">
              <span className="booking_data_question">Urgent Job:</span>
              <span className="booking_data_selection">
                {data?.urgent_job == 1 ? " Included" : " Not included"}
              </span>
            </div> */}
            </div>
          </div>

          {/* other couch and mattress  */}
          <div className="extras_booking_details_main">
            <h4>Others</h4>
            <div className="booking_details">
              {data?.other_cleaning_type && (
                <div className="booking_data">
                  <span className="booking_data_question">
                    Other cleaning type:
                  </span>
                  <span className="booking_data_selection">
                    {data?.other_cleaning_type?.label}
                  </span>
                </div>
              )}

              <div className="booking_data">
                <span className="booking_data_question">Room carpet:</span>
                <span className="booking_data_selection">
                  {data?.room_carpet ? data?.room_carpet?.label : "None"}
                </span>
              </div>

              <div className="booking_data">
                <span className="booking_data_question">Lounge carpet:</span>
                <span className="booking_data_selection">
                  {data?.lounge_carpet ? data?.lounge_carpet?.label : "None"}
                </span>
              </div>

              <div className="booking_data">
                <span className="booking_data_question">Mattress:</span>
                <span className="booking_data_selection">
                  {data?.mattress ? data?.mattress?.label : "None"}
                </span>
              </div>

              <div className="booking_data">
                <span className="booking_data_question">Couch:</span>
                <span className="booking_data_selection">
                  {data?.couch ? data?.couch?.label : "None"}
                </span>
              </div>
            </div>
          </div>

          <p className="privacy_terms_statement">
            By clicking pay you are agreeing the payment terms and conditions{" "}
            <span onClick={() => setIsmodalOpen(true)}>
              <LiaExternalLinkAltSolid size={20} />{" "}
            </span>
          </p>

          <div className="card_container">
            <div className="sub_card_container">
              {stripePromise && clientSeceret && (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret: clientSeceret }}
                >
                  <CheckoutForm
                    amount={newdiscountedAmount}
                    // amount={
                    //   parseFloat(data?.net_total) + parseFloat(tax_amount)
                    // }
                    callBack={sendData}
                  />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./modal.css";
import Modal from "react-modal";
import "animate.css";
import { RxCross2 } from "react-icons/rx";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { BLACK_SHADY } from "../../Constants/Colors";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
export default function SimpleModal({
  isVisible,
  setIsVisible = () => {},
  isErr = false,
  msg = "Data recieved successfully.",
}) {
  useEffect(() => {
    Modal.setAppElement("#root");
    setTimeout(() => {
      setIsVisible(false);
    }, 2800);
  }, []);

  return (
    <Modal
      isOpen={isVisible}
      className="modal animate__animated animate__zoomIn"
      style={{ display: "flex" }}
    >
      <div
        className="modal_container"
        style={{ minHeight: "31%", minWidth: "25%" }}
      >
        {isErr ? (
          <HiOutlineExclamationCircle size={34} color="red" />
        ) : (
          <IoIosCheckmarkCircleOutline size={34} color="#43c475" />
        )}
        <h5
          style={{
            color: isErr ? "red" : "#43c475",
            marginBottom: "60px",
            marginTop: "30px",
          }}
        >
          {msg}
        </h5>
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import "./about.css";
import OurStory from "./OurStory";
import QualityCard from "./QualityCard";
import QAComp from "../../Componants/QAComp/QAComp";
import RatingCard from "../../Componants/cards/RatingCard";
import LoadingSpinner from "../../Componants/LoadingSpinner/LoadingSpinner";
import NoDataView from "../../Componants/NoDataView/NoDataView";
import { BASE_URL, ErrorCode } from "../../Constants/constantVariable";
import { getAllReviews } from "../../Api/review";
import { getAllFaqs } from "../../Api/faq";
import { checkList } from "../../Assets";
import Carousel from "../../Componants/ReviewCarousal";
import Intro from "../../Componants/Intro/Intro";

export default function AboutUs() {
  let [isLoading, setIsLoading] = useState(false);
  let [isFaqLoading, setIsFaqLoading] = useState(false);
  let [isErrMsg, setIsErrMsg] = useState("");
  let [isFaqErrMsg, setIsFaqErrMsg] = useState("");
  let [cardsData, setCardsData] = useState([]);
  let [faqData, setFaqData] = useState([]);

  useEffect(() => {
    fetchCardsData();
    fetchFaqData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fetchCardsData = () => {
    setIsLoading(true);
    getAllReviews()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setCardsData(data.result);
        } else if (data.error_code == ErrorCode.not_exist) {
          setIsErrMsg("No Data to View Yet!");
        } else {
          setIsErrMsg("Oops! Some error occured.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsErrMsg("Network Error.");
      });
  };

  const fetchFaqData = () => {
    setIsFaqLoading(true);
    getAllFaqs()
      .then(({ data }) => {
        setIsFaqLoading(false);
        if (data.error_code == ErrorCode.success) {
          setFaqData(data.result);
        } else if (data.error_code == ErrorCode.not_exist) {
          setIsFaqErrMsg("No Data to View Yet!");
        } else {
          setIsFaqErrMsg("Oops! Some error occured.");
        }
      })
      .catch((err) => {
        setIsFaqLoading(false);
        setIsFaqErrMsg("Network Error.");
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <article className="about_page">
      {/* <ModalComp isVisible={isVisible} setIsVisible={setIsVisible} isFrom /> */}
      {/* first container */}
      <div className="about_main">
        <p className="about_content">About Our Company</p>
      </div>

      <div className="our_story">
        {/* intro about Us */}
        <Intro />
        <OurStory
          img={checkList}
          heading={"Your Trusted Cleaning Partner"}
          description={
            "Welcome to our world of cleaning excellence! With years of experience and a dedicated team, we've become a reliable choice for all your cleaning needs. Serving over 100 loyal customers, we take pride in our commitment to top-quality service."
          }
        />

        {/* Review Carousal  */}
        <Carousel />
      </div>

      <div className="home_faq_container about">
        <strong>FAQ</strong>
        {isFaqLoading ? (
          <LoadingSpinner />
        ) : faqData.length == 0 ? (
          <NoDataView msg={isFaqErrMsg} size={1.4} />
        ) : (
          faqData.map((data, index) => (
            <QAComp
              index={index + 1}
              question={data?.question}
              awnser={data?.answer}
              // bgColor={"#2f2f2f8c"}
            />
          ))
        )}
      </div>
    </article>
  );
}

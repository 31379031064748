import React from "react";
import "./style.css";

export default function NoDataView({ msg = "No Data to View", size = 1 }) {
  return (
    <div className="no_data_view" style={{ fontSize: `${16 * size}px` }}>
      {msg}
    </div>
  );
}

import React from "react";
import overallReview from "../../Assets/OverallReviews.jpg";
import rating from "../../Assets/rating.png";
import vacuum from "../../Assets/vacuum-cleaner.png";
import connected from "../../Assets/connection.png";
import certificate from "../../Assets/certificate.png";
import bar from "../../Assets/minus.png";
import NumberAnimation from "./NumberAnimation";
function OverallReviews() {
  return (
    <div className="bg-white">
      <div
        className="w-full h-[55vh] flex items-center justify-center"
        style={{
          background: `url(${overallReview})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // backgroundPositionY: "-150px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="w-full grid grid-cols-12 justify-center items-center md:gap-0">
          <div className="col-span-6 md:col-span-3 flex flex-col justify-center items-center gap-1 md:gap-3 pb-5">
            <img className="w-16 md:w-20" src={rating} alt="" />
            <img className="w-20 h-10" src={bar} alt="" />
            <div className="text-lg sm:text-2xl md:text-4xl font-bold text-white">
              <NumberAnimation
                sign={"+"}
                start={0}
                end={1500}
                duration={1000}
              />
            </div>
            <div className="text-white text-center">Happy Customers</div>
          </div>
          <div className="col-span-6 md:col-span-3 flex flex-col justify-center items-center gap-1 md:gap-3 pb-5">
            <img className="w-16 md:w-20" src={certificate} alt="" />
            <img className="w-20 h-10" src={bar} alt="" />
            <div className="text-lg sm:text-2xl md:text-4xl font-bold text-white">
              <NumberAnimation sign={"%"} start={0} end={100} duration={1000} />
            </div>
            <div className="text-white text-center">Service Guarantee</div>
          </div>
          <div className="col-span-6 md:col-span-3 flex flex-col justify-center items-center gap-1 md:gap-3 pb-5">
            <img className="w-16 md:w-20" src={connected} alt="" />
            <img className="w-20 h-10" src={bar} alt="" />
            <div className="text-lg sm:text-2xl md:text-4xl font-bold text-white">
              <NumberAnimation sign={"+"} start={0} end={30} duration={1000} />
            </div>
            <div className="text-white text-center">Cleaners</div>
          </div>
          <div className="col-span-6 md:col-span-3 flex flex-col justify-center items-center gap-1 md:gap-3 pb-5">
            <img className="w-16 md:w-20" src={vacuum} alt="" />
            <img className="w-20 h-10" src={bar} alt="" />
            <div className="text-lg sm:text-2xl md:text-4xl font-bold text-white">
              <NumberAnimation sign={""} start={0} end={1000} duration={1000} />
            </div>
            <div className="text-white text-center">Cleans Completed</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverallReviews;

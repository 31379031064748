import React from "react";
import "./option.css";
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

export default function Option({ text, notIncluded = false }) {
  return (
    <div className="option_container">
      <span className="option_icon_span">
        {notIncluded ? <RxCross2 size={18} /> : <GiCheckMark size={18} />}
      </span>
      <span className="option_text_span">{text}</span>
    </div>
  );
}

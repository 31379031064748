import React from "react";
import Select from "react-select";
import { PRIMARY } from "../../Constants/Colors";

export default function SelectionOption({
  width = "",
  isMulti = false,
  placeholder = "Select",
  err = false,
  question = "Do you have an up to date police check?",
  value = null,
  options = [],
  onSelect = () => {},
}) {
  return (
    <div className="career_selection_option" style={{ width: width }}>
      <label className="blur_contact_form_field_text contactUs career">
        {question}
      </label>
      <div className="property_info_select">
        <Select
          placeholder={placeholder}
          defaultValue={value}
          isMulti={isMulti}
          onChange={(value) => onSelect(value)}
          options={options}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "#ffffff80",
              //   backgroundColor: state.isFocused ? "white" : "#F5F6FA",
              //   width: "310px",
              border: err ? `1px solid red` : `1px solid white`,
              borderColor: err ? "red" : `white`,
              borderRadius: "8px",
              fontSize: "1.1rem",
              padding: "5px 13px",
            }),
          }}
        />
      </div>
    </div>
  );
}

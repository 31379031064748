import React from "react";

export default function DetailsContact({
  state = null,
  emailErr,
  phoneErr,
  onChange = () => {},
}) {
  return (
    <div className="contact_details_container">
      <div className="contact_details_inputs">
        <input
          placeholder="Enter your first name"
          defaultValue={state?.firstName}
          type="text"
          name="firstName"
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter your last name"
          type="text"
          name="lastName"
          defaultValue={state?.lastName}
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter your phone number"
          type="number"
          name="phone"
          style={{ border: phoneErr ? "1px solid red" : "" }}
          defaultValue={state?.phone}
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter your email"
          type="email"
          name="email"
          style={{ border: emailErr ? "1px solid red" : "" }}
          defaultValue={state?.email}
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter your street"
          type="text"
          name="street"
          defaultValue={state?.street}
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter suburb"
          type="text"
          name="suburb"
          defaultValue={state?.suburb}
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter your state"
          type="text"
          name="state"
          defaultValue={state?.state}
          onChange={(e) => onChange(e)}
        />
        <input
          placeholder="Enter post code"
          type="number"
          name="post_code"
          defaultValue={state?.post_code}
          onChange={(e) => onChange(e)}
        />
        <textarea
          className="contact_details_text_area"
          placeholder="Leave instructions for the cleaner (if any)"
          name="instruction"
          id=""
          rows="5"
          defaultValue={state?.instruction}
          onChange={(e) => onChange(e)}
        ></textarea>
      </div>
    </div>
  );
}

import React from "react";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import backImage from "../../Assets/backImg.jpeg";
import { BASE_URL } from "../../Constants/constantVariable";
import "./services.css"; // Assuming your CSS is in this file

export default function Card({ icon, heading, description, bgImg = "" }) {
  const navigate = useNavigate();
  const imageUrl = bgImg ? BASE_URL + bgImg : backImage;

  return (
    <div
      className="icon_card_services"
      style={{ boxShadow: "0 0 10px 4px whitesmoke" }}
      onClick={() => navigate("/quotation")}
    >
      <img
        src={imageUrl}
        alt="Background"
        className="icon_card_services_image"
      />
      <span className="icon_card_services_content_span">
        <strong>{heading}</strong>
        <div className="icon_card_description services line-clamp-3">
          {description.length > 95
            ? description.substring(0, 95) + "..."
            : description}
        </div>
      </span>
      <div className="icon_card_services_arrow">
        <IoArrowForwardCircleOutline size={30} />
      </div>
    </div>
  );
}

export const PRIMARY = "#2fbf66";
export const PRIMARY_DARK = "#00ad72";
export const PRIMARY_BLUE = "#39a7dc";
export const PRIMARY_DARK_BLUE = "#166175";
export const HEADINGS_LIGHT = "#696868";
export const BLACK = "#0D0D0D";
export const BLACK_SHADY = "#000000cf";
export const WHITE = "white";
export const WHITE_SMOKE = "whitesmoke";
export const BLUE = "blue";
export const RED = "#AE0000";
export const PLACEHOLDER_COLOR = "#ffffff4f";
export const LIGHT_BLUE = "#1E0D2E";
export const GRAY = "#87808E";
export const OFF_WHITE = "#F2F2F2";
export const MEDIUM_BLUE = "#0057A8";
export const LINEAR_GRADIENT_1 = "#7A9681";
export const LINEAR_GRADIENT_2 = "#7A9681";
export const LINEAR_GRADIENT_3 = "#7A9681";
export const PINK = "#CA0BD1";
export const ERROR = "#f72b24";
export const WARNING = "#f5bb1b";
export const SUCCESS = "#13eb98";

import React, { useEffect, useState } from "react";
import "./services.css";
import QualityCard from "../About-us/QualityCard";
import OurStory from "../About-us/OurStory";
import servicesImg from "../../Assets/trustedCleaningPartner.jpg";
import Card from "./Card";
import { ErrorCode } from "../../Constants/constantVariable";
import { getServiceCards } from "../../Api/service";
import { Enums } from "../../Constants/Enums";
import LoadingSpinner from "../../Componants/LoadingSpinner/LoadingSpinner";
import NoDataView from "../../Componants/NoDataView/NoDataView";
import { Rating } from "react-simple-star-rating";
import { postReview } from "../../Api/review";
import CircleCardSection from "../../Componants/CircleCards.jsx/CircleCardSection";

export default function Services() {
  let [isLoading, setIsLoading] = useState(false);
  let [isReviewLoading, setIsReviewLoading] = useState(false);
  let [nameErr, setnameErr] = useState(false);
  let [commentErr, setcommentErr] = useState(false);
  let [starErr, setstarErr] = useState(false);
  let [isErrMsg, setIsErrMsg] = useState("");
  let [isReviewErrMsg, setIsReviewErrMsg] = useState("");
  let [cardsData, setCardsData] = useState([]);
  let [qualityCardsData, setQualityCardsData] = useState([]);
  let [reviewData, setReviewData] = useState({
    id: 0,
    name: "",
    email: "",
    written_review: "",
    image: "",
    star: 0,
  });

  useEffect(() => {
    fetchCardsData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fetchCardsData = () => {
    setIsLoading(true);
    getServiceCards()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setCardsData(
            data.result.filter(
              (item) =>
                item.is_main == Enums.service_cards.sevices_cleaning_types
            )
          );
          setQualityCardsData(
            data.result.filter(
              (item) => item.is_main == Enums.service_cards.sevices_qualities
            )
          );
        } else if (data.error_code == ErrorCode.not_exist) {
          setIsErrMsg("No Data to View Yet!");
        } else {
          setIsErrMsg("Oops! Some error occured.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsErrMsg("Network Error.");
      });
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setReviewData((prev) => ({ ...prev, [name]: value }));
  }
  const onSelectImage = (img) => {
    if (
      img.type.includes("png") ||
      img.type.includes("jpg") ||
      img.type.includes("jpeg")
    ) {
      setReviewData((prev) => ({ ...prev, image: img }));
    } else {
      alert("Invalid File!");
    }
  };

  function submitReview() {
    setIsReviewErrMsg("");
    if (!reviewData.name) setnameErr(true);
    else if (!reviewData.written_review) setcommentErr(true);
    else if (reviewData.star == 0) setstarErr(true);
    else {
      const data = new FormData();
      data.append("id", "0");
      data.append("name", reviewData.name);
      data.append("star", reviewData.star);
      data.append("written_review", reviewData.written_review);
      data.append("image", reviewData.image);
      setIsReviewLoading(true);
      postReview(data)
        .then(({ data }) => {
          setIsReviewLoading(false);
          if (data.error_code == ErrorCode.success) {
            setReviewData(() => ({
              name: "",
              email: "",
              written_review: "",
              image: "",
              star: 0,
            }));
          } else if (data.error_code == ErrorCode.failed) {
            setIsReviewErrMsg(
              "Some server error occured. Please try again later."
            );
          } else {
            setIsReviewErrMsg(
              "Some server error occured. Please try again later."
            );
          }
        })
        .catch((err) => {
          setIsReviewLoading(false);
          setIsReviewErrMsg("Network error.");
        });
    }
  }

  return (
    <article className="about_page">
      {/* <ModalComp isVisible={isVisible} setIsVisible={setIsVisible} isFrom /> */}
      {/* first container */}
      <div className="about_main">
        <p className="about_content">Our Services for Satisfaction</p>
      </div>

      <div
        className="our_story"
        style={{ backgroundColor: "transparent", paddingBottom: "0" }}
      >
        {/* <div className="services_main_line_container">
          <h3>Customized Cleaning Excellence: Our Wide Service Range</h3>
          <p>
            Explore our comprehensive and professional cleaning solutions
            tailored to your specific needs. As a dedicated cleaning partner in
            Perth, we are committed to delivering top-notch services that exceed
            expectations and promote a cleaner and healthier environment.
          </p>
        </div>
        <div className="cards_container">
          {isLoading ? (
            <LoadingSpinner />
          ) : cardsData?.length == 0 ? (
            <NoDataView msg={isErrMsg} size={1.6} />
          ) : (
            cardsData?.map((item) => (
              <Card
                // icon={item?.icon_number}
                heading={item?.heading}
                description={item?.description}
                bgImg={item.image}
              />
            ))
          )}
        </div> */}

        {/* CircleCard */}
        <CircleCardSection cardsData={cardsData} />

        <div className="services_why_us_main">
          <OurStory
            img={servicesImg}
            heading={"Your Trusted Cleaning Partner"}
            description={
              "Welcome to our world of cleaning excellence! With years of experience and a dedicated team, we've become a reliable choice for all your cleaning needs. Serving over 100 loyal customers, we take pride in our commitment to top-quality service."
            }
            bg="transparent"
            isContentbg
          />
        </div>
        <div style={{ width: "100%", backgroundColor: "white" }}>
          <h3
            style={{
              backgroundColor: "white",
              fontSize: "1.25rem",
              fontWeight: "600",
              marginTop: "0%",
              color: "rgb(75 91 104)",
            }}
          >
            We Consistently Deliver the Finest Cleaning Services, Every Single
            Time!
          </h3>
        </div>
        {qualityCardsData?.length > 0 && (
          <div className="quality_container" style={{ marginBottom: "0" }}>
            {isLoading ? (
              <LoadingSpinner />
            ) : qualityCardsData?.length == 0 ? (
              <NoDataView msg={isErrMsg} size={1.6} />
            ) : (
              qualityCardsData?.map((item) => (
                <QualityCard
                  heading={item?.heading}
                  description={item?.description}
                />
              ))
            )}
          </div>
        )}
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            paddingBottom: "100px",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              fontSize: "1.2rem",
              fontWeight: "600",
              marginTop: "2%",
              color: "rgb(75 91 104)",
            }}
          >
            Review Our Service
          </h3>
          <div className="review_form" style={{ position: "relative" }}>
            {isReviewErrMsg || starErr ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: "240px",
                  color: "red",
                }}
              >
                {starErr ? "Please give rating." : isReviewErrMsg}
              </div>
            ) : null}
            <div className="rating_photo_container">
              <label className="photo_container">
                {reviewData.image ? (
                  <img
                    src={
                      reviewData.image
                        ? URL.createObjectURL(reviewData.image)
                        : ""
                    }
                    alt="cp"
                    height="100%"
                    width="100%"
                  />
                ) : (
                  <span>Upload Photo </span>
                )}
                <input
                  name="image"
                  type="file"
                  alt="upload photo"
                  onChange={(e) => onSelectImage(e.target.files[0])}
                  onClick={(event) => (event.currentTarget.value = null)}
                  accept=".png, .jpg, .jpeg"
                />
              </label>
              <div className="ratings">
                <Rating
                  size={40}
                  fillColor="#f4f737"
                  emptyColor="whitesmoke"
                  SVGstyle={{ display: "inline-block" }}
                  initialValue={reviewData.star}
                  onClick={(value) => {
                    setstarErr(false);
                    handleChange({ target: { name: "star", value: value } });
                  }}
                />
              </div>
            </div>
            <input
              name="name"
              type="text"
              placeholder="Name"
              className="review_inputs"
              style={{ border: nameErr ? "1px solid red" : "" }}
              value={reviewData.name}
              onChange={(e) => {
                setnameErr(false);
                handleChange(e);
              }}
            />
            {/* <input
              name="email"
              type="text"
              placeholder="Email"
              className="review_inputs"
              onChange={handleChange}
            /> */}
            <textarea
              name="written_review"
              id=""
              cols="30"
              rows="7"
              className="review_inputs"
              style={{ border: nameErr ? "1px solid red" : "" }}
              value={reviewData.written_review}
              onChange={(e) => {
                setcommentErr(false);
                handleChange(e);
              }}
              placeholder="Write your suggestions and comments."
            />
            <button className="submit_button" onClick={submitReview}>
              {isReviewLoading ? <LoadingSpinner /> : "Submit"}
            </button>
          </div>

          {/* <QAComp
            question={"How do I schedule a cleaning service?"}
            awnser={
              "Absolutely. We prioritize your well-being and use eco-friendly cleaning products and methods that are safe for both your family and pets. Our commitment to a healthier environment extends to the products we use in your space."
            }
          /> */}
        </div>
      </div>
    </article>
  );
}

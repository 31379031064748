import React, { useEffect, useState } from "react";
import "./career.css";
import LoadingSpinner from "../../Componants/LoadingSpinner/LoadingSpinner";
import { ErrorCode } from "../../Constants/constantVariable";
import SimpleModal from "../../Componants/modal/SimpleModal";
import SelectionOption from "./SelectionOption";
import { Lists } from "../../Constants/Lists";
import UploadFile from "../../Componants/uploadFile/UploadFile";
import { careerPostApi } from "../../Api/career";
import { ERROR, WARNING, WHITE } from "../../Constants/Colors";
import { ToastContainer, toast } from "react-toastify";

export default function Career() {
  let [isLoading, setIsLoading] = useState(false);
  let [isErr, setIsErr] = useState(false);
  let [cvErr, setcvErr] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [isErrMsg, setIsErrMsg] = useState("msg");
  let [state, setState] = useState({
    id: 0,
    email: "",
    full_name: "",
    suburb: "",
    days_available: [],
    abn: "",
    police_check: "",
    own_cleaning_supplies: "",
    own_transport: "",
    public_liability_insurance: "",
    experience: "",
    phone: "",
    apply_from: "",
    CV: "",
  });
  let [stateErrs, setStateErrs] = useState({
    email: false,
    full_name: false,
    suburb: false,
    days_available: false,
    abn: false,
    police_check: false,
    own_cleaning_supplies: false,
    own_transport: false,
    public_liability_insurance: false,
    experience: false,
    phone: false,
    apply_from: false,
    CV: false,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setStateErrs((prev) => ({ ...prev, [name]: false }));
    setState((prev) => ({ ...prev, [name]: value }));
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setIsErr(false);
    if (isValidDateValue()) {
      const formData = new FormData();
      formData.append("full_name", state.full_name);
      formData.append("suburb", state.suburb);
      formData.append("abn", state.abn);
      formData.append("police_check", state.police_check.value);
      formData.append(
        "own_cleaning_supplies",
        state.own_cleaning_supplies.value
      );
      formData.append("own_transport", state.own_transport.value);
      formData.append(
        "public_liability_insurance",
        state.public_liability_insurance.value
      );
      formData.append("experience", state.experience.value);
      formData.append("phone", state.phone);
      formData.append("apply_from", state.apply_from.value);
      formData.append("cv", state.CV);
      state.days_available.forEach((day, index) => {
        formData.append(`career_available_work_days[${index}][day]`, day.value);
      });
      setIsLoading(true);
      careerPostApi(formData)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == ErrorCode.success) {
            clearForm();
            setIsSuccess(true);
            setIsErrMsg("Data recieved successfully.");
            setTimeout(() => {
              setIsSuccess(false);
            }, 1000);
          } else {
            toast.warn("Oops! Some error occured");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Network Error");
        });
    } else {
      setIsErr(true);
    }
  };

  function setErr(name, value) {
    setStateErrs((prev) => ({ ...prev, [name]: value }));
  }
  function isValidDateValue() {
    if (!state.full_name) setErr("full_name", true);
    else if (!state.email) setErr("email", true);
    else if (!state.suburb) setErr("suburb", true);
    else if (!state.days_available.length > 0) setErr("days_available", true);
    else if (!state.police_check) setErr("police_check", true);
    else if (!state.own_cleaning_supplies)
      setErr("own_cleaning_supplies", true);
    else if (!state.abn) setErr("abn", true);
    else if (!state.public_liability_insurance)
      setErr("public_liability_insurance", true);
    else if (!state.own_transport) setErr("own_transport", true);
    else if (!state.experience) setErr("experience", true);
    else if (!state.phone) setErr("phone", true);
    else if (!state.apply_from) setErr("apply_from", true);
    else if (!state.CV) setErr("CV", true);
    else return true;
    return false;
  }

  const clearForm = () => {
    let clearedData = {
      email: "",
      full_name: "",
      suburb: "",
      days_available: [],
      abn: "",
      police_check: "",
      own_cleaning_supplies: "",
      own_transport: "",
      public_liability_insurance: "",
      experience: "",
      phone: "",
      apply_from: "",
      CV: "",
    };
    setState(clearedData);
  };

  const onSelectFile = (file) => {
    if (file.type.includes("pdf")) {
      setState((prev) => ({ ...prev, CV: file }));
      setcvErr(false);
    } else {
      toast.error("Invalid File!!");
    }
  };

  return (
    <>
      <ToastContainer
        autoClose={1000}
        position="bottom-right"
        hideProgressBar
      />
      <SimpleModal
        isVisible={isSuccess}
        setIsVisible={isErr ? setIsErr : setIsSuccess}
        msg={isErrMsg}
      />
      <main className="ContactUs_main career">
        <div className="ContactUs_container career">
          <form
            className="blur_contact_form contactUs career"
            id="contact_form"
            onSubmit={submitHandler}
          >
            <div className="contact_form_heading_main">
              <p className="contact_form_heading">Get Onboard with Us</p>
              <p className="text-sm">
                You have a current and clean police clearance certificate, and
                reliable vehicle. We receive a vast number of applications. We
                will not reply to your application unless we have a vacancy for
                you. Please feel free to reapply on a regular basis to keep you
                at the front of the list of applicants for when a vacancy comes
                up.
              </p>
            </div>
            <div className="blur_contact_form_field contactUs career">
              <label
                for="name"
                className="blur_contact_form_field_text contactUs"
              >
                Your Full Name
              </label>
              <input
                autoComplete="off"
                type="text"
                name="full_name"
                id="name"
                autoFocus
                disabled={isLoading}
                value={state.full_name}
                placeholder="Enter your name"
                onChange={(e) => handleChange(e)}
                style={{ border: stateErrs.full_name ? "1px solid red" : "" }}
              />
              <div
                className="error_msg"
                style={{ display: stateErrs.full_name ? "flex" : "none" }}
              >
                required*
              </div>
            </div>

            <div className="blur_contact_form_field contactUs career">
              <label
                for="name"
                className="blur_contact_form_field_text contactUs"
              >
                Your Email Address
              </label>
              <input
                autoComplete="off"
                type="email"
                name="email"
                id="name"
                autoFocus
                disabled={isLoading}
                value={state.email}
                placeholder="Enter your email"
                onChange={(e) => handleChange(e)}
                style={{ border: stateErrs.email ? "1px solid red" : "" }}
              />
              <div
                className="error_msg"
                style={{ display: stateErrs.email ? "flex" : "none" }}
              >
                required*
              </div>
            </div>

            <div className="blur_contact_form_field contactUs career">
              <label
                for="suburb"
                className="blur_contact_form_field_text contactUs"
              >
                Which suburb do you live in?
              </label>
              <input
                autoComplete="off"
                type="text"
                name="suburb"
                id="name"
                autoFocus
                disabled={isLoading}
                value={state.suburb}
                placeholder="Enter your suburb"
                onChange={(e) => handleChange(e)}
                style={{ border: stateErrs.suburb ? "1px solid red" : "" }}
              />
              <div
                className="error_msg"
                style={{ display: stateErrs.suburb ? "flex" : "none" }}
              >
                required*
              </div>
            </div>

            <div className="check_box_container">
              <SelectionOption
                err={stateErrs.days_available}
                width="100%"
                isMulti
                question="What days are you available to work?"
                value={state.days_available}
                options={Lists.week_days}
                onSelect={(value) => {
                  setStateErrs((prev) => ({ ...prev, days_available: false }));
                  setState((prev) => ({ ...prev, days_available: value }));
                }}
              />
            </div>

            <div className="check_box_container">
              <SelectionOption
                err={stateErrs.police_check}
                value={state.police_check}
                options={Lists.yes_no_options}
                onSelect={(value) => {
                  setStateErrs((prev) => ({ ...prev, police_check: false }));
                  setState((prev) => ({ ...state, police_check: value }));
                }}
              />
              <SelectionOption
                err={stateErrs.own_cleaning_supplies}
                question="Do you have your own cleaning supplies?"
                value={state.own_cleaning_supplies}
                options={Lists.yes_no_options}
                onSelect={(value) => {
                  setStateErrs((prev) => ({
                    ...prev,
                    own_cleaning_supplies: false,
                  }));
                  setState((prev) => ({
                    ...state,
                    own_cleaning_supplies: value,
                  }));
                }}
              />
            </div>

            <div className="blur_contact_form_field contactUs career">
              <label
                for="suburb"
                className="blur_contact_form_field_text contactUs"
              >
                What is your ABN?
              </label>
              <input
                autoComplete="off"
                type="text"
                name="abn"
                id="name"
                autoFocus
                disabled={isLoading}
                value={state.name}
                placeholder="Enter your ABN"
                onChange={(e) => handleChange(e)}
                style={{ border: stateErrs.abn ? "1px solid red" : "" }}
              />
              <div
                className="error_msg"
                style={{ display: stateErrs.abn ? "flex" : "none" }}
              >
                required*
              </div>
            </div>

            <div className="check_box_container">
              <SelectionOption
                err={stateErrs.public_liability_insurance}
                question="Do you have Public Liability Insurance?"
                value={state.public_liability_insurance}
                options={Lists.yes_no_options}
                onSelect={(value) => {
                  setStateErrs((prev) => ({
                    ...prev,
                    public_liability_insurance: false,
                  }));
                  setState((prev) => ({
                    ...state,
                    public_liability_insurance: value,
                  }));
                }}
              />
              <SelectionOption
                err={stateErrs.own_transport}
                question="Do you have your own transport?"
                value={state.own_transport}
                options={Lists.yes_no_options}
                onSelect={(value) => {
                  setStateErrs((prev) => ({ ...prev, own_transport: false }));
                  setState((prev) => ({ ...state, own_transport: value }));
                }}
              />
            </div>

            <div className="check_box_container">
              <SelectionOption
                err={stateErrs.experience}
                width="100%"
                question="How much experience do you have cleaning?"
                value={state.experience}
                options={Lists.experience_options}
                onSelect={(value) => {
                  setStateErrs((prev) => ({ ...prev, experience: false }));
                  setState((prev) => ({ ...prev, experience: value }));
                }}
              />
            </div>

            <div className="blur_contact_form_field contactUs career">
              <label
                for="name"
                className="blur_contact_form_field_text contactUs"
              >
                Your Mobile Number
              </label>
              <input
                autoComplete="off"
                type="number"
                name="phone"
                id="name"
                autoFocus
                disabled={isLoading}
                value={state.phone}
                placeholder="Enter your phone"
                onChange={(e) => handleChange(e)}
                style={{ border: stateErrs.phone ? "1px solid red" : "" }}
              />
              <div
                className="error_msg"
                style={{ display: stateErrs.phone ? "flex" : "none" }}
              >
                required*
              </div>
            </div>

            <div className="check_box_container">
              <SelectionOption
                err={stateErrs.apply_from}
                width="100%"
                question="Where did you hear about us?"
                value={state.apply_from}
                options={Lists.refrence_options}
                onSelect={(value) => {
                  setStateErrs((prev) => ({ ...prev, apply_from: false }));
                  setState((prev) => ({ ...prev, apply_from: value }));
                }}
              />
            </div>

            <div>
              <UploadFile
                label={"Upload Your CV"}
                file={state.CV}
                error={cvErr ? "Please select a thumbnail*" : ""}
                onSelect={(file) => {
                  setcvErr(false);
                  onSelectFile(file);
                }}
                onRemove={() => {
                  setState((prev) => ({ ...prev, CV: "" }));
                }}
              />
            </div>

            <div style={{ position: "relative" }}>
              <button
                disabled={isLoading}
                className="blur_contact_form_button contactUs"
                type="submit"
              >
                {isLoading ? <LoadingSpinner /> : "Send"}
              </button>
              <div
                className="error_msg"
                style={{
                  display: isErr ? "flex" : "none",
                  top: "10px",
                  right: "20px",
                }}
              >
                All fields are required
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

import React, { useState } from "react";
import "./qa.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { HEADINGS_LIGHT } from "../../Constants/Colors";

export default function QAComp({ question, awnser, index = "1", bgColor }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`qa_main ${isOpen ? "open" : ""}`}
      style={{ backgroundColor: bgColor ?? "" }}
      onClick={() => setIsOpen(!isOpen)}
    >
      <title>
        <span style={{ display: "flex" }}>
          <span
            style={{
              transition: "0.5s ease-in-out",
              color: isOpen ? "#33b9ff" : bgColor ? "white" : "",
              marginRight: "5px",
            }}
          >
            {index}.
          </span>
          <span
            style={{
              transition: "0.5s ease-in-out",
              color: isOpen ? "#33b9ff" : bgColor ? "white" : "",
            }}
          >
            {question}
          </span>
        </span>
        {isOpen ? (
          <IoIosArrowUp
            size={24}
            color={isOpen ? "#33b9ff" : bgColor ? "white" : HEADINGS_LIGHT}
          />
        ) : (
          <IoIosArrowDown
            size={24}
            color={isOpen ? "#33b9ff" : bgColor ? "white" : HEADINGS_LIGHT}
          />
        )}
      </title>
      <div className="qa_answer" style={{ color: bgColor ? "white" : "" }}>
        {awnser}
      </div>
    </div>
  );
}

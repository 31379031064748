import React, { useEffect, useState } from "react";
import "./qoutation.css";
import PropertyInfo from "./PropertyInfo";
import { IoBed } from "react-icons/io5";
import { SiDeepnote } from "react-icons/si";
import { GiBathtub } from "react-icons/gi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExtraComp from "./ExtraComp";
import {
  Goals,
  add,
  balcony,
  curtain,
  doubleFridge,
  fridge,
  garage,
  livingRoom,
  windows,
} from "../../Assets";
import TimeContainer from "./TimeContainer";
import AdditionalQuestion from "./AdditionalQuestion";
import DetailsContact from "./DetailsContact";
import { Enums } from "../../Constants/Enums";
import { useLocation, useNavigate } from "react-router-dom";
import { Lists } from "../../Constants/Lists";
import {
  getCarpetPrices,
  getDiscounts,
  getPopertyPrices,
} from "../../Api/qoutation";
import { ErrorCode, THRESHOLD_AMOUNT } from "../../Constants/constantVariable";
import { getAllExtraPrices } from "../../Api/extras";
import Option from "../../Componants/option/Option";
import { FaMattressPillow, FaCouch } from "react-icons/fa6";
import { IoTabletLandscape } from "react-icons/io5";
import Payment from "../Payment/Payment";
import { ToastContainer, toast } from "react-toastify";

export default function Qoutation() {
  const navigate = useNavigate();

  const location = useLocation();
  const data = location?.state;

  const [thresholdAmountErr, setThresholdAmountErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const [timeErr, setTimeErr] = useState(false);
  const [formErr, setFormErr] = useState(false);
  const [formEmailErr, setFormEmailErr] = useState(false);
  const [netTotal, setNetTotal] = useState(
    data?.net_total ? data?.net_total : 0
  );
  const [discount_info, setDiscount_info] = useState({
    property_discount_rate: 0,
    other_discount_rate: 0,
    property_discount: "",
    other_discount: "",
  });
  const [timeSlots, settimeSlots] = useState(Lists.timeSlots);
  const [options, setOptions] = useState(Lists);
  const [propertyPrices, setPropertyPrices] = useState([]);
  const [carpetPrices, setCarpetPrices] = useState([]);
  const [extrasPrices, setExtrasPrices] = useState([]);
  const [extrasTotalPrice, setextrasTotalPrice] = useState(0);
  const [stateTotalPrice, setstateTotalPrice] = useState(
    data?.stateTotalPrice ? data?.stateTotalPrice : 0
  );
  const [othersStateTotalPrice, setOthersStateTotalPrice] = useState(
    data?.othersStateTotalPrice ? data?.othersStateTotalPrice : 0
  );
  const [isPaymentPage, setIsPaymentPage] = useState(false);
  let [dataState, setdataState] = useState(null);

  const [extras, setExtras] = useState({
    balcony: data?.balcony ? data?.balcony : null,
    wall: data?.wall ? data?.wall : null,
    windows: data?.windows ? data?.windows : null,
    blind: data?.blind ? data?.blind : null,
    garage: data?.garage ? 1 : 0,
    misc: data?.misc ? data?.misc : null,
    fridge: data?.fridge ? 1 : 0,
    double_fridge: data?.double_fridge ? 1 : 0,
    living_area: data?.living_area ? 1 : 0,
    carpet: data?.carpet ? data?.carpet : null,
    pressure_washing: data?.pressure_washing ? 1 : 0,
    travel_fee: data?.travel_fee ? 1 : 0,
    urgent_job: data?.urgent_job ? 1 : 0,
  });
  const [extrasPricesValues, setExtrasPricesValues] = useState({
    balcony: 0,
    wall: 0,
    windows: 0,
    blind: 0,
    garage: 0,
    misc: 0,
    fridge: 0,
    double_fridge: 0,
    living_area: 0,
    carpet: 0,
    pressure_washing: 0,
    travel_fee: 0,
    urgent_job: 0,
  });
  const [state, setState] = useState({
    firstName: data?.firstName ? data?.firstName : "",
    lastName: data?.lastName ? data?.lastName : "",
    phone: data?.phone ? data?.phone : "",
    email: data?.email ? data?.email : "",
    street: data?.street ? data?.street : "",
    suburb: data?.suburb ? data?.suburb : "",
    state: data?.state ? data?.state : "",
    post_code: data?.post_code ? data?.post_code : "",
    instruction: data?.instruction ? data?.instruction : "",
    storey: data?.storey ? data?.storey : { value: 1, label: "1 Storey" },
    bedroom: data?.bedroom ? data?.bedroom : { value: 1, label: "1 Bedroom" },
    bathroom: data?.bathroom
      ? data?.bathroom
      : { value: 1, label: "1 Bathroom" },
    date_of_booking: data?.date_of_booking ? data?.date_of_booking : null,
    time_of_booking: data?.time_of_booking ? data?.time_of_booking : null,
    time_value: data?.time_value ? data?.time_value : 0,
    is_home: data?.is_home ? data?.is_home : Enums.is_home.yes,
    cleaning_type: data?.cleaning_type
      ? data?.cleaning_type
      : options.cleaning_type[0],
    property_type: data?.property_type
      ? data?.property_type
      : Enums.property_type.un_furnished,
    is_power_available: data?.is_power_available
      ? data?.firstName
      : Enums.is_power_available.yes,
  });
  const [statePrices, setStatePrices] = useState({
    storey: 0,
    cleaning_type: 0,
    bedroom: 0,
    bathroom: 0,
  });
  const [carpetCouchState, setCarpetCouchState] = useState({
    cleaning_type: data?.other_cleaning_type
      ? data.other_cleaning_type
      : options.cleaning_type[0],
    room_carpet: data?.room_carpet ? data?.room_carpet : "",
    lounge_carpet: data?.lounge_carpet ? data?.lounge_carpet : "",
    matress: data?.mattress ? data?.mattress : "",
    couch: data?.couch ? data?.couch : "",
  });
  const [carpetCouchPrices, setCarpetCouchPrices] = useState({
    // carpet_type: 0,
    cleaning_type: 0,
    room_carpet: 0,
    lounge_carpet: 0,
    matress: 0,
    couch: 0,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetchPropertyPrices();
    fetchCarpetPrices();
    fetchExtrasPrices();
    fetchDiscount();
  }, []);

  useEffect(() => {
    let finalPrice = 0;
    Object.entries(extrasPricesValues).map((entry) => {
      finalPrice += entry[1];
    });
    let newTotal = netTotal - extrasTotalPrice;
    setextrasTotalPrice(finalPrice);
    setNetTotal(newTotal + finalPrice);
  }, [extrasPricesValues]);

  useEffect(() => {
    let finalPrice = 0;
    Object.entries(statePrices).map((entry) => {
      finalPrice += entry[1];
    });
    let newTotal = netTotal - stateTotalPrice;
    setstateTotalPrice(finalPrice);
    setNetTotal(newTotal + finalPrice);
  }, [statePrices]);

  useEffect(() => {
    let finalPrice = 0;
    Object.entries(carpetCouchPrices).map((entry) => {
      finalPrice += entry[1];
    });
    let newTotal = netTotal - othersStateTotalPrice;
    setOthersStateTotalPrice(finalPrice);
    setNetTotal(newTotal + finalPrice);
  }, [carpetCouchPrices]);

  useEffect(() => {
    setThresholdAmountErr(false);
  }, [netTotal]);

  function fetchPropertyPrices() {
    getPopertyPrices()
      .then(({ data }) => {
        if (data.error_code == ErrorCode.success) {
          setPropertyPrices(data.result);
          setNetTotalPrice(data.result);
        }
      })
      .catch((err) => toast.error("Network Error"));
  }

  function fetchCarpetPrices() {
    getCarpetPrices()
      .then(({ data }) => {
        if (data.error_code == ErrorCode.success) {
          setCarpetPrices(data.result);
          setNetTotalPrice(null, data.result);
        }
      })
      .catch((err) => toast.error("Network Error"));
  }

  function fetchDiscount() {
    getDiscounts()
      .then(({ data }) => {
        if (data.error_code === ErrorCode.success) {
          data.result?.forEach((item) => {
            if (item.discount_type === Enums.discount_type.property)
              setDiscount_info((prev) => ({
                ...prev,
                property_discount: item.discount_text,
                property_discount_rate: item.discount,
              }));
            else
              setDiscount_info((prev) => ({
                ...prev,
                other_discount: item.discount_text,
                other_discount_rate: item.discount,
              }));
          });
        }
      })
      .catch((err) => {
        toast.error("Network Error");
      });
  }

  function fetchExtrasPrices() {
    getAllExtraPrices()
      .then(({ data }) => {
        if (data.error_code == ErrorCode.success) setExtrasPrices(data.result);
      })
      .catch((err) => toast.error("Network Error"));
  }

  function setNetTotalPrice(pricesArray, carpetPrices) {
    if (!data) {
      let bedroomPriceObject = pricesArray?.find(
        (item) =>
          item.bedroom == 1 &&
          item.storey == 1 &&
          item.cleaning_type === Enums.cleaning_type.standard
      );
      let bathroomPriceObject = pricesArray?.find(
        (item) =>
          item.bathroom === 1 &&
          item.storey === 1 &&
          item.cleaning_type === Enums.cleaning_type.standard
      );

      if (bedroomPriceObject)
        setStatePrices((prev) => ({
          ...prev,
          bedroom: bedroomPriceObject?.price,
        }));
      if (bathroomPriceObject)
        setStatePrices((prev) => ({
          ...prev,
          bathroom: bathroomPriceObject?.price,
        }));
    }
    // if(carpetPrices){

    // }
  }

  function handleChange(e) {
    if (e.target.name == "email") setFormEmailErr(false);
    if (e.target.name == "phone") setFormErr(false);
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  }

  function handleOthersChange(e) {
    const { name, value } = e.target;
    setCarpetCouchState((prev) => ({ ...prev, [name]: value }));
  }

  function handleExtrasChange(e) {
    const { name, value } = e.target;
    setExtras((prev) => ({ ...prev, [name]: value }));
  }

  function handleExtrasPriceChange(name, obj, extra_id) {
    if (name == "misc") {
      setExtrasPricesValues((prev) => ({
        ...prev,
        [name]: obj.value,
      }));
    } else {
      let priceObject = extrasPrices?.find(
        (item) => item.extra_id == extra_id && item.quantity == obj?.value
      );
      if (obj.value == 0 || !priceObject)
        setExtrasPricesValues((prev) => ({ ...prev, [name]: 0 }));
      else
        setExtrasPricesValues((prev) => ({
          ...prev,
          [name]: priceObject?.price,
        }));
    }
  }

  function handleStatePriceChange(name, obj, storey, type) {
    //select the item from the prices array in which storey matches the given value and type matches the type value for the name field.
    let priceObject = propertyPrices?.find((item) => {
      return (
        (name === "bedroom"
          ? item.bedroom == obj?.value
          : item.bathroom === obj?.value) &&
        item.storey === storey?.value &&
        item.cleaning_type === type?.value
      );
    });

    if (!priceObject) setStatePrices((prev) => ({ ...prev, [name]: 0 }));
    else
      setStatePrices((prev) => ({
        ...prev,
        [name]: priceObject?.price,
      }));
  }

  function handleCarpetCouchStatePriceChange(
    name,
    obj,
    type = { value: Enums.cleaning_type.standard }
  ) {
    //select the item from the prices array in which storey matches the given value and type matches the type value for the name field.
    let priceObject;
    if (name == "room_carpet") {
      priceObject = carpetPrices?.find(
        (item) =>
          item.room_carpet == obj.value && item.cleaning_type == type.value
      );
    } else if (name == "lounge_carpet") {
      priceObject = carpetPrices?.find(
        (item) =>
          item.lounge_carpet == obj.value && item.cleaning_type == type.value
      );
    } else if (name == "couch") {
      priceObject = carpetPrices?.find(
        (item) => item.couch == obj.value && item.cleaning_type == type.value
      );
    } else if (name == "matress") {
      priceObject = carpetPrices?.find(
        (item) => item.mattress == obj.value && item.cleaning_type == type.value
      );
    }
    if (!priceObject) setCarpetCouchPrices((prev) => ({ ...prev, [name]: 0 }));
    else
      setCarpetCouchPrices((prev) => ({
        ...prev,
        [name]: priceObject?.price,
      }));
  }

  async function checkOutClick() {
    if (netTotal < THRESHOLD_AMOUNT) {
      setThresholdAmountErr(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      toast.info("Amount is less than threshold!");
    } else if (!state.date_of_booking) setDateErr(true);
    else if (!state.time_of_booking) setTimeErr(true);
    else if (!state.phone) setFormErr(true);
    else if (!state.email) setFormEmailErr(true);
    else {
      let otherState = {
        other_cleaning_type: carpetCouchState.cleaning_type,
        room_carpet: carpetCouchState.room_carpet,
        lounge_carpet: carpetCouchState.lounge_carpet,
        mattress: carpetCouchState.matress,
        couch: carpetCouchState.couch,
        stateDiscount: discount_info.property_discount_rate,
        otherDiscount: discount_info.other_discount_rate,
        stateTotalPrice: stateTotalPrice,
        othersTotalPrice: othersStateTotalPrice,
        carpetCouchPrices: carpetCouchPrices, //these are added for retaining the state of the values on return back from payment page
        statePrices: statePrices,
        extrasPricesValues: extrasPricesValues,
      };

      setdataState(
        (dataState = {
          ...state,
          ...extras,
          ...otherState,
          net_total: netTotal,
        })
      );
      setIsPaymentPage(true);
      // let data = { ...state, ...extras, ...otherState, net_total: netTotal };
      // navigate("/quotation/payment", { state: data });
    }
  }
  return (
    <>
      <ToastContainer
        autoClose={1500}
        hideProgressBar
        position="bottom-right"
      />
      {isPaymentPage ? (
        <Payment
          dataState={dataState}
          onBackClick={() => setIsPaymentPage(false)}
        />
      ) : (
        <article className="quotation_main">
          <div className="about_main quotation">
            <p className="about_content quotation">Get A Quotation</p>
            <div className="quotation_price_main_container">
              <div className="quotation_price_main">
                <div className="quotation_price_tag_line">
                  End of Lease Cleaning
                </div>
                <div className="quotation_price_sub_container">
                  <div className="quotation_price_sub_container_content">
                    <div className="quotation_price_sub_container_heading">
                      Price Estimation
                    </div>
                    <div className="quotation_price_sub_container_text">
                      Service price estimation x Subject to Inspection
                    </div>
                  </div>
                  <div
                    className="quotation_price_sub_container_netToatal"
                    style={{ color: thresholdAmountErr ? "red" : "" }}
                  >
                    ${netTotal}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quotation_main_container">
            <p>
              {
                "AWS Property Cleaning Services Facilities Management, can provide all your end of lease cleaning needs book you vacate clean now on our price estimation link."
              }
            </p>

            {/* property details  */}
            <div className="Property_container">
              <h2>
                1. Property Details
                {discount_info.property_discount && (
                  <span className="discount_info">
                    {discount_info.property_discount}
                  </span>
                )}
              </h2>
              <div className="Property_container_comps">
                <PropertyInfo
                  value={state.storey}
                  options={options.storey}
                  onSelect={(value) => {
                    handleStatePriceChange(
                      "bedroom",
                      state.bedroom,
                      value,
                      state.cleaning_type
                    );
                    handleStatePriceChange(
                      "bathroom",
                      state.bathroom,
                      value,
                      state.cleaning_type
                    );
                    handleChange({ target: { name: "storey", value: value } });
                  }}
                />
                <PropertyInfo
                  Icon={SiDeepnote}
                  question="Cleaning Type"
                  value={state.cleaning_type}
                  options={options.cleaning_type}
                  onSelect={(value) => {
                    handleStatePriceChange(
                      "bedroom",
                      state.bedroom,
                      state.storey,
                      value
                    );
                    handleStatePriceChange(
                      "bathroom",
                      state.bathroom,
                      state.storey,
                      value
                    );
                    handleChange({
                      target: { name: "cleaning_type", value: value },
                    });
                  }}
                />
                <PropertyInfo
                  Icon={IoBed}
                  question="How many bedrooms?"
                  value={state.bedroom}
                  options={options.bedroom}
                  onSelect={(value) => {
                    handleStatePriceChange(
                      "bedroom",
                      value,
                      state?.storey,
                      state.cleaning_type
                    );
                    handleChange({ target: { name: "bedroom", value: value } });
                  }}
                />
                <PropertyInfo
                  Icon={GiBathtub}
                  question="How many Bathrooms?"
                  value={state.bathroom}
                  options={options.bathroom}
                  onSelect={(value) => {
                    handleStatePriceChange(
                      "bathroom",
                      value,
                      state.storey,
                      state.cleaning_type
                    );
                    handleChange({
                      target: { name: "bathroom", value: value },
                    });
                  }}
                />
              </div>
            </div>

            {/* inclusion  */}
            {/* <div className="inclusion_container_main">
          <h2>Inclusions</h2>
          <div className="inclusion_containers">
            <InclusionComp
              text={"Bathroom"}
              list={Lists.bathroom_cleaning_list}
            />
            <InclusionComp
              text={"Kitchen"}
              list={Lists.kitchen_cleaning_list}
            />
          </div>
          <div className="inclusion_containers">
            <InclusionComp
              text={"Bedrooms / Entrance / Hallway / Lounge / Dining Rooms"}
              list={Lists.mains_cleaning_list}
            />
            <InclusionComp text={"Oven"} list={Lists.oven_cleaning_list} />
          </div>
          <div className="inclusion_containers">
            <InclusionComp
              text={"Wardrobes / Cupboards"}
              list={Lists.wardrobes_cleaning_list}
            />
            <InclusionComp
              text={"Pantry / Cupboards"}
              list={Lists.pantry_cleaning_list}
            />
          </div>
          <div className="inclusion_containers">
            <InclusionComp
              text={"Laundry"}
              list={Lists.laundry_cleaning_list}
            />
          </div>
        </div> */}

            <div className="inclusion_container_main">
              <div>
                <h2 style={{ padding: "1% 0", paddingBottom: "0" }}>
                  Standard Cleaning
                </h2>
                <div className="inclusion_container_main_option">
                  <div className="options_main_container">
                    <Option text={"Vacuming"} />
                    <Option text={"Mopping"} />
                    <Option text={"Emptying bins"} />
                    <Option text={"Wiping surfaces"} />
                    <Option text={"Skirting boards"} />
                    <Option text={"Ceiling fans"} />
                    <Option text={"Shelves"} />
                    <Option text={"Outside cabnets"} />
                  </div>
                  <div className="options_main_container">
                    <Option text={"Inside cabnets"} notIncluded />
                    <Option text={"Blinds"} notIncluded />
                    <Option text={"Inside windows and Tracks"} notIncluded />
                    <Option text={"Wall Marks"} notIncluded />
                    <Option text={"Exhaust fans"} notIncluded />
                    <Option text={"Stove Top"} />
                    <Option text={"Microwave"} />
                  </div>
                  <div className="options_main_container">
                    <Option text={"Sinks"} />
                    <Option text={"Countertops"} />
                    <Option text={"Inside microwave"} notIncluded />
                    <Option text={"Inside oven"} notIncluded />
                    <Option text={"Toilet"} />
                    <Option text={"Shower / Bath"} />
                    <Option text={"Mirrors"} />
                  </div>
                </div>

                {/* small view  */}
                <div className="inclusion_container_main_option small_view">
                  <Option text={"Vacuming"} />
                  <Option text={"Mopping"} />
                  <Option text={"Emptying bins"} />
                  <Option text={"Wiping surfaces"} />
                  <Option text={"Skirting boards"} />
                  <Option text={"Ceiling fans"} />
                  <Option text={"Shelves"} />
                  <Option text={"Outside cabnets"} />
                  <Option text={"Inside cabnets"} notIncluded />
                  <Option text={"Blinds"} notIncluded />
                  <Option text={"Inside windows and Tracks"} notIncluded />
                  <Option text={"Wall Marks"} notIncluded />
                  <Option text={"Exhaust fans"} notIncluded />
                  <Option text={"Stove Top"} />
                  <Option text={"Microwave"} />
                  <Option text={"Sinks"} />
                  <Option text={"Countertops"} />
                  <Option text={"Inside microwave"} notIncluded />
                  <Option text={"Inside oven"} notIncluded />
                  <Option text={"Toilet"} />
                  <Option text={"Shower / Bath"} />
                  <Option text={"Mirrors"} />
                </div>
              </div>
              <div>
                <h2 style={{ padding: "1% 0", paddingBottom: "0" }}>
                  Deep Cleaning
                </h2>
                <div className="inclusion_container_main_option">
                  <div className="options_main_container">
                    <Option text={"Vacuming"} />
                    <Option text={"Mopping"} />
                    <Option text={"Emptying bins"} />
                    <Option text={"Wiping surfaces"} />
                    <Option text={"Skirting boards"} />
                    <Option text={"Ceiling fans"} />
                    <Option text={"Shelves"} />
                    <Option text={"Outside cabnets"} />
                  </div>
                  <div className="options_main_container">
                    <Option text={"Inside cabnets"} />
                    <Option text={"Blinds"} />
                    <Option text={"Inside windows and Tracks"} />
                    <Option text={"Wall Marks"} />
                    <Option text={"Exhaust fans"} />
                    <Option text={"Stove Top"} />
                    <Option text={"Microwave"} />
                  </div>
                  <div className="options_main_container">
                    <Option text={"Sinks"} />
                    <Option text={"Countertops"} />
                    <Option text={"Inside microwave"} />
                    <Option text={"Inside oven"} />
                    <Option text={"Toilet"} />
                    <Option text={"Shower / Bath"} />
                    <Option text={"Mirrors"} />
                  </div>
                </div>

                {/* small view deep */}
                <div className="inclusion_container_main_option small_view">
                  <Option text={"Vacuming"} />
                  <Option text={"Mopping"} />
                  <Option text={"Emptying bins"} />
                  <Option text={"Wiping surfaces"} />
                  <Option text={"Skirting boards"} />
                  <Option text={"Ceiling fans"} />
                  <Option text={"Shelves"} />
                  <Option text={"Outside cabnets"} />
                  <Option text={"Inside cabnets"} />
                  <Option text={"Blinds"} />
                  <Option text={"Inside windows and Tracks"} />
                  <Option text={"Wall Marks"} />
                  <Option text={"Exhaust fans"} />
                  <Option text={"Stove Top"} />
                  <Option text={"Microwave"} />
                  <Option text={"Sinks"} />
                  <Option text={"Countertops"} />
                  <Option text={"Inside microwave"} />
                  <Option text={"Inside oven"} />
                  <Option text={"Toilet"} />
                  <Option text={"Shower / Bath"} />
                  <Option text={"Mirrors"} />
                </div>
              </div>
            </div>

            {/* Extrass  */}
            <div className="extras_main">
              <h2>Extras</h2>
              <div className="extras_container_main">
                <div className="extras_containers">
                  <ExtraComp
                    image={balcony}
                    name="Balcony"
                    quantity={3}
                    value={extras.balcony}
                    onSelect={(value) => {
                      if (value)
                        handleExtrasPriceChange(
                          "balcony",
                          value,
                          Enums.extras.balcony
                        );
                      else
                        handleExtrasPriceChange(
                          "balcony",
                          { value: 0 },
                          Enums.extras.balcony
                        );
                      handleExtrasChange({
                        target: { name: "balcony", value: value },
                      });
                    }}
                  />
                  <ExtraComp
                    text="Wall Wash"
                    selectQuestion="How many rooms you would like wall wash?"
                    name="Room"
                    value={extras.wall}
                    onSelect={(value) => {
                      if (value)
                        handleExtrasPriceChange(
                          "wall",
                          value,
                          Enums.extras.wall_wash
                        );
                      else
                        handleExtrasPriceChange(
                          "wall",
                          { value: 0 },
                          Enums.extras.wall_wash
                        );
                      handleExtrasChange({
                        target: { name: "wall", value: value },
                      });
                    }}
                  />
                </div>
                <div className="extras_containers">
                  <ExtraComp
                    text="Outside window Clean"
                    image={windows}
                    name="windows"
                    selectQuestion="How many windows you need to clean?"
                    value={extras.windows}
                    onSelect={(value) => {
                      if (value)
                        handleExtrasPriceChange(
                          "windows",
                          value,
                          Enums.extras.window
                        );
                      else
                        handleExtrasPriceChange(
                          "windows",
                          { value: 0 },
                          Enums.extras.window
                        );
                      handleExtrasChange({
                        target: { name: "windows", value: value },
                      });
                    }}
                  />
                  <ExtraComp
                    text="Blinds"
                    image={curtain}
                    name="Blinds"
                    selectQuestion="How many blinds you would like to clean?"
                    value={extras.blind}
                    onSelect={(value) => {
                      if (value)
                        handleExtrasPriceChange(
                          "blind",
                          value,
                          Enums.extras.blinds
                        );
                      else
                        handleExtrasPriceChange(
                          "blind",
                          { value: 0 },
                          Enums.extras.blinds
                        );
                      handleExtrasChange({
                        target: { name: "blind", value: value },
                      });
                    }}
                  />
                </div>
                <div className="extras_containers">
                  <ExtraComp
                    text="Garage Clean"
                    image={garage}
                    isNotSelect
                    value={extras.garage == 1}
                    onIsCheckedValue={(isChecked) => {
                      if (isChecked)
                        handleExtrasPriceChange(
                          "garage",
                          { value: 1 },
                          Enums.extras.garage
                        );
                      else
                        handleExtrasPriceChange(
                          "garage",
                          { value: 0 },
                          Enums.extras.garage
                        );
                      handleExtrasChange({
                        target: { name: "garage", value: isChecked ? 1 : 0 },
                      });
                    }}
                  />
                  <ExtraComp
                    text="Living Area"
                    image={livingRoom}
                    isNotSelect
                    value={extras.living_area == 1}
                    onIsCheckedValue={(isChecked) => {
                      if (isChecked)
                        handleExtrasPriceChange(
                          "living_area",
                          { value: 1 },
                          Enums.extras.living_area
                        );
                      else
                        handleExtrasPriceChange(
                          "living_area",
                          { value: 0 },
                          Enums.extras.living_area
                        );
                      handleExtrasChange({
                        target: {
                          name: "living_area",
                          value: isChecked ? 1 : 0,
                        },
                      });
                    }}
                  />
                  {/* <ExtraComp
                    text="Misc"
                    name="misc"
                    image={add}
                    selectQuestion="Add miscellaneous service (by price)"
                    value={extras.misc}
                    onSelect={(value) => {
                      if (value)
                        handleExtrasPriceChange(
                          "misc",
                          value,
                          Enums.extras.misc
                        );
                      else
                        handleExtrasPriceChange(
                          "misc",
                          { value: 0 },
                          Enums.extras.misc
                        );
                      handleExtrasChange({
                        target: { name: "misc", value: value },
                      });
                    }}
                  /> */}
                </div>
                <div className="extras_containers">
                  <ExtraComp
                    text="Fridge"
                    image={fridge}
                    isNotSelect
                    value={extras.fridge == 1}
                    onIsCheckedValue={(isChecked) => {
                      if (isChecked)
                        handleExtrasPriceChange(
                          "fridge",
                          { value: 1 },
                          Enums.extras.fridge
                        );
                      else
                        handleExtrasPriceChange(
                          "fridge",
                          { value: 0 },
                          Enums.extras.fridge
                        );
                      handleExtrasChange({
                        target: { name: "fridge", value: isChecked ? 1 : 0 },
                      });
                    }}
                  />
                  <ExtraComp
                    text="Pressure Washing"
                    image={Goals}
                    isNotSelect
                    value={extras.pressure_washing == 1}
                    onIsCheckedValue={(isChecked) => {
                      if (isChecked)
                        handleExtrasPriceChange(
                          "pressure_washing",
                          { value: 1 },
                          Enums.extras.pressure_washing
                        );
                      else
                        handleExtrasPriceChange(
                          "pressure_washing",
                          { value: 0 },
                          Enums.extras.pressure_washing
                        );
                      handleExtrasChange({
                        target: {
                          name: "pressure_washing",
                          value: isChecked ? 1 : 0,
                        },
                      });
                    }}
                  />
                </div>
                <div className="extras_containers">
                  <ExtraComp
                    text="Double Fridge"
                    image={doubleFridge}
                    isNotSelect
                    value={extras.double_fridge == 1}
                    onIsCheckedValue={(isChecked) => {
                      if (isChecked)
                        handleExtrasPriceChange(
                          "double_fridge",
                          { value: 1 },
                          Enums.extras.double_fridge
                        );
                      else
                        handleExtrasPriceChange(
                          "double_fridge",
                          { value: 0 },
                          Enums.extras.double_fridge
                        );
                      handleExtrasChange({
                        target: {
                          name: "double_fridge",
                          value: isChecked ? 1 : 0,
                        },
                      });
                    }}
                  />

                  {/* <ExtraComp
                text="Travel Fee"
                image={Goals}
                isNotSelect
                value={extras.travel_fee == 1}
                onIsCheckedValue={(isChecked) => {
                  if (isChecked)
                    handleExtrasPriceChange(
                      "travel_fee",
                      { value: 1 },
                      Enums.extras.travel_fee
                    );
                  else
                    handleExtrasPriceChange(
                      "travel_fee",
                      { value: 0 },
                      Enums.extras.travel_fee
                    );
                  handleExtrasChange({
                    target: { name: "travel_fee", value: isChecked ? 1 : 0 },
                  });
                }}
              /> */}
                </div>
                {/* <div className="extras_containers">
              
              <ExtraComp
                text="Urgent Job"
                image={Goals}
                isNotSelect
                value={extras.urgent_job == 1}
                onIsCheckedValue={(isChecked) => {
                  if (isChecked)
                    handleExtrasPriceChange(
                      "urgent_job",
                      { value: 1 },
                      Enums.extras.urgent_job
                    );
                  else
                    handleExtrasPriceChange(
                      "urgent_job",
                      { value: 0 },
                      Enums.extras.urgent_job
                    );
                  handleExtrasChange({
                    target: { name: "urgent_job", value: isChecked ? 1 : 0 },
                  });
                }}
              />
            </div> */}
                {/* <div className="extras_containers">
              <ExtraComp
                text="Carpet Steam Clean"
                image={cleaner}
                name="Room"
                selectQuestion="How many rooms you would steam?"
                value={extras.carpet}
                onSelect={(value) => {
                  if (value)
                    handleExtrasPriceChange(
                      "carpet",
                      value,
                      Enums.extras.carpet_steam_clean
                    );
                  else
                    handleExtrasPriceChange(
                      "carpet",
                      { value: 0 },
                      Enums.extras.carpet_steam_clean
                    );
                  handleExtrasChange({
                    target: { name: "carpet", value: value },
                  });
                }}
              />
            </div> */}
              </div>
            </div>

            {/* Carpet/couch details  */}

            {/* room carpet  */}
            <div className="Property_container">
              <h2>
                1.1. Others
                {discount_info.other_discount && (
                  <span className="discount_info" style={{ left: "180px" }}>
                    {discount_info.other_discount}
                  </span>
                )}
              </h2>
              <div className="Property_container_comps">
                <PropertyInfo
                  Icon={SiDeepnote}
                  question="Cleaning Type"
                  value={carpetCouchState.cleaning_type}
                  options={options.cleaning_type}
                  onSelect={(value) => {
                    handleCarpetCouchStatePriceChange(
                      "room_carpet",
                      carpetCouchState.room_carpet,
                      value
                    );
                    handleCarpetCouchStatePriceChange(
                      "lounge_carpet",
                      carpetCouchState.lounge_carpet,
                      value
                    );
                    handleCarpetCouchStatePriceChange(
                      "couch",
                      carpetCouchState.couch,
                      value
                    );
                    handleCarpetCouchStatePriceChange(
                      "matress",
                      carpetCouchState.matress,
                      value
                    );
                    handleOthersChange({
                      target: { name: "cleaning_type", value: value },
                    });
                  }}
                />
                <PropertyInfo
                  Icon={IoTabletLandscape}
                  question="Room Carpets"
                  value={carpetCouchState.room_carpet}
                  options={options.no_of_carpets}
                  onSelect={(value) => {
                    handleCarpetCouchStatePriceChange(
                      "room_carpet",
                      value,
                      carpetCouchState.cleaning_type
                    );
                    handleOthersChange({
                      target: { name: "room_carpet", value: value },
                    });
                  }}
                />
                <PropertyInfo
                  Icon={IoTabletLandscape}
                  question="Lounge Carpets"
                  value={carpetCouchState.lounge_carpet}
                  options={options.no_of_carpets}
                  onSelect={(value) => {
                    handleCarpetCouchStatePriceChange(
                      "lounge_carpet",
                      value,
                      carpetCouchState.cleaning_type
                    );
                    handleOthersChange({
                      target: { name: "lounge_carpet", value: value },
                    });
                  }}
                />
                <PropertyInfo
                  Icon={FaCouch}
                  question="Couch"
                  value={carpetCouchState.couch}
                  options={options.couch}
                  onSelect={(value) => {
                    handleCarpetCouchStatePriceChange(
                      "couch",
                      value,
                      carpetCouchState.cleaning_type
                    );
                    handleOthersChange({
                      target: { name: "couch", value: value },
                    });
                  }}
                />
                <PropertyInfo
                  Icon={FaMattressPillow}
                  question="Mattress"
                  value={carpetCouchState.matress}
                  options={options.mattress}
                  onSelect={(value) => {
                    handleCarpetCouchStatePriceChange(
                      "matress",
                      value,
                      carpetCouchState.cleaning_type
                    );
                    handleOthersChange({
                      target: { name: "matress", value: value },
                    });
                  }}
                />
              </div>
            </div>

            {/* schedule */}
            <main className="date_time_main">
              <h2>2. Date & Time</h2>
              <p>Select the right date & time for cleaning job booking.</p>
              <div className="date_time_date_container">
                <h4>Select Date for Booking</h4>
                <div className="date_time_date_picker_container">
                  <div className="date_picker_modal">
                    <DatePicker
                      placeholderText="Select booking date"
                      closeOnScroll={true}
                      dateFormat="dd-MM-yyyy"
                      className={dateErr ? "dateErr" : `datePicker`}
                      minDate={new Date()}
                      selected={state.date_of_booking}
                      onChange={(date) => {
                        setDateErr(false);
                        handleChange({
                          target: { name: "date_of_booking", value: date },
                        });
                      }}
                    />
                  </div>
                  <p>
                    If you're booking on Sunday, please give us a call at:{" "}
                    <span> 08 6244 2297</span>
                  </p>
                </div>
                <div className="time_container">
                  <p>
                    When would you like the cleaner to arrive?
                    <span>*</span>
                  </p>
                  <div className="time_comps_container">
                    {timeSlots.map((timeSlot) => (
                      <TimeContainer
                        time={timeSlot.label}
                        index={timeSlot.value}
                        value={state.time_value}
                        onSelect={(index) => {
                          setTimeErr(false);
                          handleChange({
                            target: {
                              name: "time_value",
                              value: state.time_value === index ? null : index,
                            },
                          });
                          handleChange({
                            target: {
                              name: "time_of_booking",
                              value: timeSlot,
                            },
                          });
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="schedule_additional_questions_container">
                <AdditionalQuestion
                  question="Will you be home?"
                  selectedValue={state.is_home}
                  onSelect={(data) =>
                    handleChange({
                      target: { name: "is_home", value: data.value },
                    })
                  }
                />
                <AdditionalQuestion
                  question="Select Property Type"
                  options={[
                    { value: 0, label: "Unfurnised" },
                    { value: 1, label: "Furnised" },
                  ]}
                  selectedValue={state.property_type}
                  onSelect={(data) =>
                    handleChange({
                      target: { name: "property_type", value: data.value },
                    })
                  }
                />
                <AdditionalQuestion
                  question="Power Availability"
                  selectedValue={state.is_power_available}
                  onSelect={(data) =>
                    handleChange({
                      target: { name: "is_power_available", value: data.value },
                    })
                  }
                />
              </div>
            </main>

            {/* Personal Details  */}
            <main className="details_main">
              <h2>3. Your Details</h2>
              <p>Please Enter your details</p>
              <DetailsContact
                state={state}
                onChange={handleChange}
                emailErr={formEmailErr}
                phoneErr={formErr}
              />
            </main>

            <div className="qoutation_pyament_btn_main">
              {dateErr || timeErr || formErr || formEmailErr ? (
                <div className="buttonErrDiv">
                  {dateErr
                    ? "Please select a date for booking*"
                    : timeErr
                    ? "Please select a time slot of booking.*"
                    : "Please fill the contact information form*"}
                </div>
              ) : null}
              <button onClick={checkOutClick}>Check out to Pay</button>
            </div>
          </div>
        </article>
      )}
    </>
  );
}

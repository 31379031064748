import React, { useEffect } from "react";
import "./blog.css";
import banner from "../../Assets/checkList.jpg";
import { useNavigate } from "react-router-dom";
import { blogs } from "../../Componants/BlogsData/Data";
export default function Blogs() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-full bg-white pt-[160px]">
      {blogs?.map((items, index) => (
        <div
          key={index}
          className="w-[80%] md:w-[50%] m-auto flex flex-col gap-4 pb-10"
        >
          <h2 className="text-2xl md:text-4xl text-[#4b5b68] font-bold">
            {items.title}
          </h2>
          <p className="text-[#677D8F] text-base">{items.firstContent}</p>
          <span>
            <img src={items.img} height={"100%"} width={"100%"} />
          </span>
          <p className="text-[#677D8F] text-base">{items.secondContent}</p>
          <div
            className="detail_post_read_btn"
            onClick={() => navigate("/blog-detail", { state: { blog: items } })}
          >
            Read Post
          </div>
        </div>
      ))}
    </div>
  );
}

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Componants/Navbar";
import HomePage from "./screens/Home/HomePage";
import AboutUs from "./screens/About-us/AboutUs";
import Services from "./screens/Services/Services";
import ContactUs from "./screens/Contact-us/ContactUs";
import Footer from "./Componants/Footer/Footer";
import Qoutation from "./screens/qoutation/qoutation";
import Payment from "./screens/Payment/Payment";
import { STRIPE_PUBLISH_KEY } from "./Constants/constantVariable";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeSuccessPage from "./screens/StripeCheckoutScreens/StripeSuccessPage";
import Career from "./screens/career/Career.jsx";
import "react-toastify/dist/ReactToastify.css";
import Blogs from "./screens/blogs/Blogs.jsx";
import BlogDetail from "./screens/blogDetail/BlogDetail.jsx";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);
  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    // <StripeProvider>
    <Elements stripe={stripePromise} options={options}>
      <BrowserRouter>
        <div>
          <Navbar />
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/quotation" element={<Qoutation />} />
          <Route path="/quotation/payment" element={<Payment />} />
          <Route path="/booking-completion" element={<StripeSuccessPage />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-detail" element={<BlogDetail />} />
        </Routes>
        <div>
          <Footer />
        </div>
      </BrowserRouter>
    </Elements>
    // </StripeProvider>
  );
}

export default App;

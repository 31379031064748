import React, { useEffect } from "react";
import "./blogdetail.css";
import { useLocation } from "react-router-dom";

export default function BlogDetail() {
  const location = useLocation();
  const { blog } = (location && location.state) || {};

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-full bg-white pt-[160px]">
      <div className="w-[80%] md:w-[60%] m-auto flex flex-col gap-4 pb-10">
        {blog ? (
          <>
            <span className="blog_img_container">
              <img src={blog.img} height={"100%"} width={"100%"} alt={blog.title} />
            </span>
            <h2 className="text-2xl md:text-4xl text-[#4b5b68] font-bold">{blog.title}</h2>
            <p className="text-[#677D8F] text-base">{blog.firstContent}</p>
            <p className="text-[#677D8F] text-base">{blog.secondContent}</p>

            <div>
              <h1 className="text-xl md:text-3xl text-[#4b5b68] font-bold">{blog.heading1}</h1>
              <p className="text-[#677D8F] text-base py-4">{blog.para1}</p>
            </div>
            <div>
              <h1 className="text-xl md:text-3xl text-[#4b5b68] font-bold">{blog.heading2}</h1>
              <p className="text-[#677D8F] text-base py-4">{blog.para2}</p>
            </div>
            <div>
              <h1 className="text-xl md:text-3xl text-[#4b5b68] font-bold">{blog.heading3}</h1>
              <p className="text-[#677D8F] text-base py-4">{blog.para3}</p>
            </div>
            <div>
              <h1 className="text-xl md:text-3xl text-[#4b5b68] font-bold">{blog.heading4}</h1>
              <p className="text-[#677D8F] text-base py-4">{blog.para4}</p>
            </div>
            <div>
              <h1 className="text-xl md:text-3xl text-[#4b5b68] font-bold">{blog.heading5}</h1>
              <p className="text-[#677D8F] text-base py-4">{blog.para5}</p>
            </div>
          </>
        ) : (
          <p>No blog data available.</p>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Enums } from "../../Constants/Enums";
import "./contactUs.css";
import "../../Componants/contactFormComp/BlurContact.css";
import "../../Componants/contactFormComp/contact.css";
import { contactUsPost } from "../../Api/ContactUs";
import LoadingSpinner from "../../Componants/LoadingSpinner/LoadingSpinner";
import { ErrorCode } from "../../Constants/constantVariable";
import SimpleModal from "../../Componants/modal/SimpleModal";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import hrbar from "../../Assets/hrBar.png";

export default function ContactUs() {
  let [isLoading, setIsLoading] = useState(false);
  let [isInvalidData, setIsInvalidData] = useState(false);
  let [isCountryErr, setIsCountryErr] = useState(false);
  let [isBusinessNameErr, setIsBusinessNameErr] = useState(false);
  let [isNameErr, setIsNameErr] = useState(false);
  let [isErr, setIsErr] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [isErrMsg, setIsErrMsg] = useState("msg");
  let [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    address: "",
    country: "",
    business_name: "",
    want_updates: Enums.recieve_content.no,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "first_name") setIsNameErr(false);
    else if (name === "country") setIsCountryErr(false);
    else if (name === "business_name") setIsBusinessNameErr(false);
    setUser((prev) => ({ ...prev, [name]: value }));
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setIsErr(false);
    if (isValidData()) {
      setIsLoading(true);
      contactUsPost(user)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code == ErrorCode.success) {
            setIsSuccess(true);
            setIsErrMsg("Data recieved successfully.");
            clearForm();
            setTimeout(() => {
              setIsSuccess(false);
            }, 1000);
          } else {
            setIsErr(true);
            setIsErrMsg("Oops! some error occured.");
          }
        })
        .catch((err) => {
          setIsErr(true);
          setIsLoading(false);
          setIsErrMsg("Network Error.");
        });
    }
  };

  function isValidData() {
    if (!user?.first_name) setIsNameErr(true);
    else if (!user?.phone) setIsInvalidData(true);
    else if (!user?.country) setIsCountryErr(true);
    else if (!user?.business_name) setIsBusinessNameErr(true);
    else return true;
    return false;
  }
  const clearForm = () => {
    setUser((prev) => ({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
      address: "",
      country: "",
      business_name: "",
      want_updates: 0,
    }));
  };

  return (
    <>
      <SimpleModal
        isVisible={isSuccess || isErr}
        // isVisible={true}
        isErr={isErr}
        setIsVisible={isErr ? setIsErr : setIsSuccess}
        msg={isErrMsg}
      />
      <main className="ContactUs_main">
        <div className="pt-[170px] pb-[100px] bg-white flex lg:flex-row flex-col-reverse sm:flex-col items-start">
          <div className="w-full lg:w-[30%] px-10">
            <div className="text-4xl text-[#4b5b68]">General Office</div>
            <div className=" text-xl text-[#4b5b68] font-bold pt-4 pb-3">
              Contacts
            </div>
            <div className="flex flex-wrap flex-col sm:flex-row lg:flex-col justify-between  lg:px-0">
              <div className="flex gap-4 pb-[25px]">
                <div>
                  <FaLocationDot className="text-[#6FBF52] text-2xl" />
                </div>
                <div>
                  <div className="text-lg font-bold text-[#4b5b68] pb-2">
                    Address
                  </div>
                  <div className="text-sm text-[#677D96] ">
                    151 a birkett bedford
                  </div>
                </div>
              </div>
              <div className="flex gap-4 pb-[25px]">
                <div>
                  <MdEmail className="text-[#6FBF52] text-2xl" />
                </div>
                <div>
                  <div className="text-lg font-bold text-[#4b5b68] pb-2">
                    Email
                  </div>
                  <div className="text-xs text-[#677D96] text-wrap">
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:Info@awscleaningservices.com.au"
                    >
                      Info@awscleaningservices.com.au
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex gap-4 pb-[25px]">
                <div>
                  <FaPhoneVolume className="text-[#6FBF52] text-2xl" />
                </div>
                <div>
                  <div className="text-lg font-bold text-[#4b5b68] pb-2">
                    Phone
                  </div>
                  <a href="tel:0862442297" className="text-sm text-[#677D96]">
                    08 6244 2297
                  </a>
                </div>
              </div>
            </div>
          </div>
          <form
            className=" contactUs w-full lg:w-[70%] pb-5 sm:pb-0"
            id="contact_form"
            onSubmit={submitHandler}
          >
            <div className="flex flex-col justify-center pb-4">
              <h1 className="text-4xl text-[#4b5b68] font-extrabold w-full text-center">
                Get in Touch
              </h1>
              <p className="text-xs text-[#677D96] w-[90%] md:w-[70%] m-auto text-center py-4">
                AWS Property Cleaning Services is a one-stop cleaning agency
                that will leave you feeling warm and fuzzy and your house fresh
                and sparkling at times. Our service is reasonable.
              </p>
              <div className="w-full flex justify-center">
                <img src={hrbar} alt="" />
              </div>
            </div>

            <div className="px-10 lg:px-3">
              <div className="lg:flex gap-2">
                <div className="pb-4 lg:w-[50%] relative">
                  <input
                    autoComplete="off"
                    type="text"
                    name="first_name"
                    id="name"
                    autoFocus
                    className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                    style={{ border: isNameErr ? "1px solid red" : "" }}
                    disabled={isLoading}
                    value={user.first_name}
                    placeholder="Enter your first name"
                    onChange={(e) => handleChange(e)}
                  />

                  {isNameErr && (
                    <div className="absolute text-red-600 text-xs font-semibold">
                      Required*
                    </div>
                  )}
                </div>

                <div className="pb-4 lg:w-[50%]">
                  <input
                    className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                    autoComplete="off"
                    type="text"
                    name="last_name"
                    id="name"
                    autoFocus
                    disabled={isLoading}
                    value={user.last_name}
                    placeholder="Enter your last name"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>

              <div className="lg:flex gap-2">
                <div className="pb-4 lg:w-[50%] relative">
                  <input
                    autoComplete="off"
                    type="number"
                    name="phone"
                    id="phone"
                    className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                    style={{ border: isInvalidData ? "1px solid red" : "" }}
                    autoFocus
                    disabled={isLoading}
                    value={user.phone}
                    placeholder="Enter your contact number"
                    onChange={(e) => {
                      setIsInvalidData(false);
                      handleChange(e);
                    }}
                  />
                  {isInvalidData ? (
                    <div className="absolute text-red-600 text-xs font-semibold">
                      Please provide your contact number*
                    </div>
                  ) : null}
                </div>

                <div
                  className="pb-4 lg:w-[50%]"
                  style={{ position: "relative" }}
                >
                  <input
                    className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                    autoComplete="off"
                    type="email"
                    name="email"
                    id="phone"
                    // style={{ border: isInvalidData ? "1px solid red" : "" }}
                    autoFocus
                    disabled={isLoading}
                    value={user.email}
                    placeholder="Enter your email address"
                    onChange={(e) => {
                      setIsInvalidData(false);
                      handleChange(e);
                    }}
                  />
                </div>
              </div>

              <div className="lg:flex gap-2">
                <div
                  className=" pb-4 lg:w-[50%]"
                  style={{ position: "relative" }}
                >
                  <input
                    className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                    autoComplete="off"
                    type="text"
                    name="address"
                    id="phone"
                    // style={{ border: isInvalidData ? "1px solid red" : "" }}
                    autoFocus
                    disabled={isLoading}
                    value={user.address}
                    placeholder="Enter your address"
                    onChange={(e) => {
                      setIsInvalidData(false);
                      handleChange(e);
                    }}
                  />
                </div>

                <div
                  className="pb-4 lg:w-[50%] relative"
                  style={{ position: "relative" }}
                >
                  <input
                    autoComplete="off"
                    type="text"
                    name="country"
                    id="phone"
                    className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                    style={{ border: isCountryErr ? "1px solid red" : "" }}
                    autoFocus
                    disabled={isLoading}
                    value={user.country}
                    placeholder="Enter your country"
                    onChange={(e) => {
                      setIsInvalidData(false);
                      handleChange(e);
                    }}
                  />
                  {isCountryErr && (
                    <div className="absolute text-red-600 text-xs font-semibold">
                      Required*
                    </div>
                  )}
                </div>
              </div>

              <div className="pb-4 relative" style={{ position: "relative" }}>
                <input
                  autoComplete="off"
                  type="text"
                  name="business_name"
                  id="phone"
                  className="border-[1.2px]  shadow-sm  w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                  style={{ border: isBusinessNameErr ? "1px solid red" : "" }}
                  autoFocus
                  disabled={isLoading}
                  value={user.business_name}
                  placeholder="Enter your business_name"
                  onChange={(e) => {
                    setIsInvalidData(false);
                    handleChange(e);
                  }}
                />
                {isBusinessNameErr && (
                  <div className="absolute text-red-600 text-xs font-semibold">
                    Required*
                  </div>
                )}
              </div>

              <div className="">
                <textarea
                  className="border-[1.2px]  shadow-sm w-full py-[12px] px-3 outline-none rounded-[3px] focus:border-black"
                  autoComplete="off"
                  cols="20"
                  rows="6"
                  name="message"
                  id="msg"
                  autoFocus
                  disabled={isLoading}
                  value={user.message}
                  placeholder="Enter any message you have"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="flex gap-3 py-5">
                <input
                  className="h-5 w-5"
                  type="checkbox"
                  name="want_updates"
                  disabled={isLoading}
                  value={user.want_updates === 0 ? false : true}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      want_updates: e.target.checked
                        ? Enums.recieve_content.yes
                        : Enums.recieve_content.no,
                    });
                  }}
                />
                <div className="">
                  I want to recieve updates or news from the company.
                </div>
              </div>
              <div className="flex ">
                <button
                  disabled={isLoading}
                  style={{ backgroundColor: "#3974d9", width: "50%" }}
                  className="w-[100%] font-bold"
                  type="submit"
                >
                  {isLoading ? <LoadingSpinner /> : "Send"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
}

// Carousel.js
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import person from "../Assets/carousal_person.png";
import person2 from "../Assets/carousal_person2.png";
import backImg from "../Assets/backImg.jpeg";
import double_quotes from "../Assets/double-quotes.png";
import { BASE_URL, ErrorCode } from "../Constants/constantVariable";
import { getAllReviews } from "../Api/review";
import noImage from "../Assets/avatar1.png";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import { Rating } from "react-simple-star-rating";
import { MdOutlineStar } from "react-icons/md";
// import './Carousel.css';  // Import the CSS file for custom styles

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide the default arrows
  };
  let [isLoading, setIsLoading] = useState(false);
  let [isErrMsg, setIsErrMsg] = useState("");
  let [cardsData, setCardsData] = useState([]);
  useEffect(() => {
    fetchCardsData();
  }, []);

  const fetchCardsData = () => {
    setIsLoading(true);
    getAllReviews()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setCardsData(data.result);
        } else if (data.error_code == ErrorCode.not_exist) {
          setIsErrMsg("No Data to View Yet!");
        } else {
          setIsErrMsg("Oops! Some error occured.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsErrMsg("Network Error.");
      });
  };

  const reviews = [
    {
      content:
        "They are very professional and do a great job cleaning the house!! I locked myself out of my house the other day and they were the only ones with a key. They were kind enough to drive over to unlock the door. That speaks volumes!!! They really care about their clients.",
      img: person,
      name1: " — Jesse Hudson.",
      name2: "Dothan, Alabama",
    },
    {
      content:
        "They are very professional and do a great job cleaning the house!! I locked myself out of my house the other day and they were the only ones with a key. They were kind enough to drive over to unlock the door. That speaks volumes!!! They really care about their clients.",
      img: person2,
      name1: " — Jesse Hudson.",
      name2: "Dothan, Alabama",
    },
    {
      content:
        "They are very professional and do a great job cleaning the house!! I locked myself out of my house the other day and they were the only ones with a key. They were kind enough to drive over to unlock the door. That speaks volumes!!! They really care about their clients.",
      img: person,
      name1: " — Jesse Hudson.",
      name2: "Dothan, Alabama",
    },
  ];

  return (
    <div className="carousel-container">
      <div className="text-2xl md:text-4xl px-2 sm:px-0 w-full text-center text-[#4b5b68] font-bold pb-3 sm:pb-5 md:py-10 pt-10 md:pt-20">
        Happy Customers, Happy Homes
      </div>

      <Slider {...settings}>
        {isLoading ? (
          <LoadingSpinner />
        ) : cardsData.length < 1 ? (
          reviews?.map((item) => (
            <div className="carousel-slide w-full flex">
              <div className="flex w-full sm:px-5 md:px-10 lg:px-20">
                <div className="w-[10%] hidden sm:block">
                  <img className="w-20 rotate-180" src={double_quotes} alt="" />
                </div>
                <div className="w-full sm:w-[80%]">
                  <div className="text-[#677D8F] text-base text-center px-3 sm:px-10 md:px-20">
                    {item.content}
                  </div>
                  <div className="flex flex-col items-center gap-3 justify-center py-4 ">
                    <div>
                      <img className="w-32 md:w-48" src={item.img} alt="" />
                    </div>
                    <div className="text-[#2fbf66]">
                      {item.name1}{" "}
                      <span className="text-[#677D8F]">{item.name2}</span>
                    </div>
                  </div>
                </div>
                <div className="w-[10%] hidden sm:block">
                  <div className="flex items-end h-full">
                    <img className="w-20" src={double_quotes} alt="" />
                  </div>
                </div>
              </div>{" "}
            </div>
          ))
        ) : (
          cardsData?.map((item) => (
            <div className="carousel-slide w-full flex">
              <div className="flex w-full sm:px-5 md:px-10 lg:px-20">
                <div className="w-[10%] hidden sm:block">
                  <img className="w-20 rotate-180" src={double_quotes} alt="" />
                </div>
                <div className="w-full sm:w-[80%]">
                  <div className="text-[#677D8F] text-base text-center px-3 sm:px-10 md:px-20">
                    {item.written_review}
                  </div>
                  <div className="flex flex-col items-center gap-3 justify-center py-4 ">
                    <div
                      style={{
                        height: "185px",
                        width: "185px",
                        overflow: "hidden",
                        borderRadius: "100%",
                        border: "1px solid lightgray",
                      }}
                    >
                      <img
                        className="w-32 md:w-48"
                        src={item.image ? BASE_URL + item.image : noImage}
                        // src={imgErr ? noImage : BASE_URL + item.image}
                        // onError={handleImageErr}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "fill",
                        }}
                      />
                    </div>
                    <div className="text-[#2fbf66] flex items-center">
                      <span style={{ marginRight: "10px" }}>{item.name} </span>
                      <span
                        className="text-[#677D8F] flex items-center"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {Array.from({ length: item.star }).map((_, i) => (
                          <MdOutlineStar key={i} size={19} color="#f4f737" />
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-[10%] hidden sm:block">
                  <div className="flex items-end h-full">
                    <img className="w-20" src={double_quotes} alt="" />
                  </div>
                </div>
              </div>{" "}
            </div>
          ))
        )}
      </Slider>
    </div>
  );
};

export default Carousel;

import React from "react";
import Natural from "../../Assets/NaturalCleaning.jpg";
import { ImLeaf } from "react-icons/im";

function NaturalCleaning() {
  return (
    <div className="bg-white p-10">
      <div className="flex flex-col-reverse md:flex-row  ">
        <div className="md:w-1/2 w-full py-3 md:py-5">
          <img src={Natural} alt="" />
        </div>
        <div className="px-2 md:w-1/2 w-full py-3 md:py-5">
          <div className="text-2xl md:text-4xl text-[#4b5b68]">
          <span className="text-[#2fbf66]">Natural </span> Cleaning Products
          </div>
          <div className="text-[#677D8F] text-sm py-7">
          We feel good about cleaning with our self-formulated, natural products that are better for the environment
          </div>
          <div className="flex gap-4">
            <div>
              <ImLeaf className="text-[#2fbf66] mt-2" />
            </div>
            <div>
              <div className="text-xl font-bold text-[#4b5b68]">
              100% Safe & Organic

              </div>
              <div className="text-[#677D8F] text-sm pt-6 pb-7">
              We combine high concentrates of pure organic essential oils with quality plant-derived ingredients.



              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div>
              <ImLeaf className="text-[#2fbf66] mt-2" />
            </div>
            <div>
              <div className="text-xl font-bold text-[#4b5b68]">
              We Care About The Earth

              </div>
              <div className="text-[#677D8F] text-sm pt-6 pb-7">
              All of our packaging, bottles, cleaning equipment, even our uniforms come from recycled, fairtrade or low impact origins.



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NaturalCleaning;

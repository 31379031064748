import React from "react";

export default function QualityCard({ heading, description }) {
  return (
    <div className="quality_card">
      <div className="qc_heading">{heading}</div>
      <div className="qc_description">{description}</div>
    </div>
  );
}

import { apiInstance } from "./ApiMiddleware";

export const getPopertyPrices = () => {
  const api = apiInstance();
  return api.get("api/property-detail/get-all");
};

export const getCarpetPrices = () => {
  const api = apiInstance();
  return api.get("api/carpet-cleaning-detail/get-all");
};

export const getDiscounts = () => {
  const api = apiInstance();
  return api.get("api/discount/get-all");
};

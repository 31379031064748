import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  BLACK,
  BLACK_SHADY,
  PRIMARY,
  WHITE,
  WHITE_SMOKE,
} from "../Constants/Colors";
import "./style.css"; // Make sure to import your custom styles
import { ConsoleData } from "../Constants/CommonFunctions";
import logo from "../Assets/logo1.png";
import { FaPhoneAlt } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { getDiscounts } from "../Api/qoutation";
import { ErrorCode } from "../Constants/constantVariable";
import { Enums } from "../Constants/Enums";
import { FaBell } from "react-icons/fa";
import Advertisment from "./advertisment/Advertisment";

export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollThreshold = 25;
  const navigate = useNavigate();
  const [discount_info, setDiscount_info] = useState({
    property_discount: "",
    other_discount: "",
  });

  useEffect(() => {
    fetchDiscount();
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const linkStyle = (isActive) => ({
    color: isActive ? PRIMARY : "",
    // borderBottom: isActive ? `1px solid ${PRIMARY}` : "none",
    textDecoration: "none",
    border: "none",
    fontWeight: isActive ? "700" : "600",
  });

  function fetchDiscount() {
    getDiscounts()
      .then(({ data }) => {
        if (data.error_code === ErrorCode.success) {
          data.result?.forEach((item) => {
            if (item.discount_type === Enums.discount_type.property)
              setDiscount_info((prev) => ({
                ...prev,
                property_discount: item.discount_text,
              }));
            else
              setDiscount_info((prev) => ({
                ...prev,
                other_discount: item.discount_text,
              }));
          });
        }
      })
      .catch((err) => {
        // toast.error("Network Error");
        ConsoleData("fetch adevr fail");
      });
  }

  return (
    <nav
      className={`navbar ${isNavOpen ? "open" : ""}`}
      style={{
        // backgroundColor: isNavOpen
        //   ? "black"
        //   : scrollPosition >= scrollThreshold
        //   ? BLACK_SHADY
        //   : "",
        boxShadow:
          scrollPosition >= scrollThreshold
            ? "0 0 10px rgba(0, 0, 0, 0.1)"
            : "",
      }}
    >
      {/* {discount_info.property_discount && discount_info.other_discount && (
        <Advertisment
          texts={[
            discount_info.property_discount,
            discount_info.other_discount,
          ]}
          backgroundColor={"white"}
        />
      )} */}

      <div className="navbar_main">
        <div className="navbar_main_small_view">
          <div className="navbar-header">
            <NavLink className="navbar-brand" to="/">
              <img className="w-24" src={logo} alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              onClick={toggleNav}
              aria-label="Toggle navigatio"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="navbar_book_info navbar_book_info_small_view">
            {/* <div
              className="navbar_number_container request"
              onClick={() => {
                setIsNavOpen(false);
                navigate("/quotation");
              }}
            >
              <span className="navbar_number_container_icon">
                <IoCalendarOutline size={20} color={PRIMARY} />
              </span>
              <span className="navbar_number_container_txt">Book now</span>
            </div> */}
            {/* <a href={"tel:0862442297"} className="navbar_number_container">
              <span className="navbar_number_container_icon">
                <FaPhoneAlt size={18} color={PRIMARY} />
              </span>
              <span className="navbar_number_container_txt">08 6244 2297</span>
            </a> */}
            <a href="tel:0862442297" className="request_qoute_btn call_btn">
              <FaPhoneAlt size={16} />
              {/* <span>0862442297</span> */}
            </a>
            <NavLink
              to="/quotation"
              className="book_info navbar_number_container_txt2"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              <div className="request_qoute_btn">
                <FaBell size={16} />
                <span>Request a qoute</span>
              </div>
            </NavLink>
          </div>
          {/* 
          <div className="navbar-header">
            <NavLink className="navbar-brand" to="/">
              <img className="w-24" src={logo} alt="" />
            </NavLink>
            <button
              className="navbar-toggler"
              onClick={toggleNav}
              aria-label="Toggle navigatio"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div> */}
        </div>

        <div className={"navbar_info_container hide_on_small_width"}>
          <div className="navbar_book_info" style={{ gap: "15px" }}>
            {/* <a href="tel:0862442297" className="navbar_number_container">
              <span className="navbar_number_container_icon">
                <FaPhoneAlt size={24} color={PRIMARY} />
              </span>
              <span className="navbar_number_container_txt">
                <div className="navbar_number_container_txt1">Call us on</div>
                <div className="navbar_number_container_txt2">08 6244 2297</div>
                </span>
            </a> */}
            <a href="tel:0862442297" className="request_qoute_btn">
              <FaPhoneAlt size={16} />
              <span>0862442297</span>
            </a>
            <NavLink
              to="/quotation"
              className="book_info navbar_number_container_txt2"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              <div className="request_qoute_btn">
                <FaBell size={16} />
                <span>Request a qoute</span>
              </div>
              {/* <div className="navbar_number_container">
                <span className="navbar_number_container_icon">
                  <IoCalendarOutline size={24} color={PRIMARY} />
                </span>
                <span className="navbar_number_container_txt">
                  <div className="navbar_number_container_txt1">
                    Request a Quote
                  </div>
                  <div className="navbar_number_container_txt2">Book now</div>
                </span>
              </div> */}
            </NavLink>
          </div>

          <div className={`navbar-links ${isNavOpen ? "open" : ""}`}>
            <NavLink
              to="/"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Services
            </NavLink>
            <NavLink
              to="/blogs"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Blogs
            </NavLink>
            <NavLink
              to="/contact"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/career"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Career
            </NavLink>
          </div>
        </div>

        <div className="navbar_small_device_view">
          <div className={`navbar-links ${isNavOpen ? "open" : ""}`}>
            <NavLink
              to="/"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              About Us
            </NavLink>
            <NavLink
              to="/services"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Services
            </NavLink>
            <NavLink
              to="/blogs"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Blogs
            </NavLink>
            <NavLink
              to="/quotation"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Request a qoute
            </NavLink>
            <NavLink
              to="/contact"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/career"
              className="nav-link"
              style={({ isActive }) => {
                return linkStyle(isActive);
              }}
              onClick={() => setIsNavOpen(false)}
            >
              Career
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

import { Enums } from "./Enums";

export const Lists = {
  bathroom_cleaning_list: [
    "Cleaning of all tiled floors",
    "Clean mirrors and glass surfaces",
    "Clean all tile surfaces",
    "Clean all bench tops",
    "Clean all sinks, taps and handles",
    "Clean bathtub",
    "Clean shower screens",
    "Clean mirrors",
    "Deep scrub and clean of toilet",
    "Clean internal vanity cupboards",
    "Clean internal medicine cabinets",
    "Clean all soap holders - remove soap scum",
    "Clean exhaust fan",
    "Clean high touch areas",
    "Clean light fittings where accessible",
    "Remove dust from skirting boards",
    "Clean all door and window frames",
  ],
  kitchen_cleaning_list: [
    "Degrease and wipe down all surfaces",
    "Clean external cupboards/handles",
    "Clean sink and tubs",
    "Clean all appliances including dishwasher",
    "Full detail of stove top",
    "Full detail of exhaust fan/range hood",
    "Full detail of splash backs",
    "Clean high touch areas",
    "Clean all internal cupboards (including pantry)",
    "Clean light fittings where accessible",
    "Clean all door and window frames",
  ],
  mains_cleaning_list: [
    "Remove cobwebs throughout",
    "Clean fixtures (switches/doorbell/door handles/locks)",
    "Clean high touch areas",
    "Remove dust and clean ceiling fans",
    "Clean all light switches and electrical outlets",
    "Remove dust from skirting boards.",
    "Clean light fittings where accessible",
    "Cleaning of vents (air conditioning)",
    "Clean all door and window frames.",
    "Vacuum/mop flooring (as required).",
  ],
  oven_cleaning_list: [
    "ven cleaned using proper oven cleaner",
    "Internal clean of oven/grill including glass",
    "All grease/grime and evidence of use is removed",
    "Degrease and clean grill and grill plates",
    "Wipe down and clean on front of oven",
  ],
  wardrobes_cleaning_list: [
    "Remove stickers and adhesives",
    "Clean glass surfaces",
    "Clean all shelves and drawers (all levels)",
    "Mirrored wardrobes - vacuum and clean tracks and frames",
  ],
  pantry_cleaning_list: [
    "Clean all shelves",
    "Clean all drawers",
    "Clean all doors/handles",
  ],
  laundry_cleaning_list: [
    "Remove all dust/lint",
    "Remove lint from exhaust fan (ceiling)",
    "Clean tile floors",
    "Clean sink and tubs",
    "Remove dust from skirting boards",
    "Clean light fittings where accessible",
    "Clean all door and window frames.",
    "Clean internal cupboards",
    "Clean external cupboards",
  ],
  storey: [
    { value: 1, label: "1 Storey" },
    { value: 2, label: "2 Storey" },
    { value: 3, label: "3 Storey" },
  ],
  cleaning_type: [
    { value: Enums.cleaning_type.standard, label: "Standard" },
    { value: Enums.cleaning_type.deep, label: "Deep/Vacate" },
    // { value: 3, label: "Vacate" },
  ],
  bathroom: [
    { value: -1, label: "None" },
    { value: 1, label: "1 Bathroom" },
    { value: 2, label: "2 Bathrooms" },
    { value: 3, label: "3 Bathrooms" },
    { value: 4, label: "4 Bathrooms" },
  ],
  bedroom: [
    { value: -1, label: "None" },
    { value: 1, label: "1 Bedroom" },
    { value: 2, label: "2 Bedrooms" },
    { value: 3, label: "3 Bedrooms" },
    { value: 4, label: "4 Bedrooms" },
    { value: 5, label: "5 Bedrooms" },
    { value: 6, label: "6 Bedrooms" },
  ],
  carpet_type: [
    { value: Enums.carpet_type.room, label: "Room Carpet" },
    { value: Enums.carpet_type.lounge, label: "Lounge Carpet" },
  ],
  couch: [
    { value: -1, label: "None" },
    { value: 1, label: "1 Seat" },
    { value: 2, label: "2 Seats" },
    { value: 3, label: "3 Seats" },
    { value: 4, label: "4 Seats" },
    { value: 5, label: "5 Seats" },
    { value: 6, label: "6 Seats" },
    { value: 7, label: "7 Seats" },
    { value: 8, label: "8 Seats" },
    { value: 9, label: "9 Seats" },
    { value: 10, label: "10 Seats" },
  ],
  mattress: [
    { value: -1, label: "None" },
    { value: 1, label: "1 Mattress" },
    { value: 2, label: "2 Mattresses" },
    { value: 3, label: "3 Mattresses" },
    { value: 4, label: "4 Mattresses" },
    { value: 5, label: "5 Mattresses" },
    { value: 6, label: "6 Mattresses" },
  ],
  no_of_carpets: [
    { value: -1, label: "None" },
    { value: 1, label: "1 Carpet" },
    { value: 2, label: "2 Carpets" },
    { value: 3, label: "3 Carpets" },
    { value: 4, label: "4 Carpets" },
    { value: 5, label: "5 Carpets" },
    { value: 6, label: "6 Carpets" },
  ],
  timeSlots: [
    { value: 1, label: "7:00 AM - 8:00 AM" },
    { value: 2, label: "8:00 AM - 9:00 AM" },
    { value: 3, label: "9:00 AM - 10:00 AM" },
    { value: 4, label: "10:00 AM - 11:00 AM" },
    { value: 5, label: "11:00 AM - 12:00 PM" },
    { value: 6, label: "12:00 PM - 1:00 PM" },
    { value: 7, label: "1:00 PM - 2:00 PM" },
    { value: 8, label: "2:00 PM - 3:00 PM" },
    { value: 9, label: "3:00 PM - 4:00 PM" },
    { value: 10, label: "4:00 PM - 5:00 PM" },
    { value: 11, label: "5:00 PM - 6:00 PM" },
  ],
  yes_no_options: [
    { value: 1, label: "No" },
    { value: 2, label: "Yes" },
  ],
  experience_options: [
    { value: 1, label: "Less than 6 months" },
    { value: 2, label: "6 months to a year" },
    { value: 3, label: "1 to 2 years" },
    { value: 4, label: "2 to 5 years" },
    { value: 5, label: "Over 5 Years" },
  ],
  refrence_options: [
    { value: 1, label: "Facebook" },
    { value: 2, label: "Gumtree" },
    { value: 3, label: "Seek" },
    { value: 4, label: "Indeed" },
    { value: 5, label: "Referral" },
  ],
  week_days: [
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
    { value: 7, label: "Sunday" },
  ],
};

import React, { useState } from "react";
import LoadingSpinner from "../../Componants/LoadingSpinner/LoadingSpinner";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

export default function CheckoutForm({ amount = 0, callBack = () => {} }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!stripe || !elements) return;
    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error?.message);
      setIsLoading(false);
    } else if (paymentIntent && paymentIntent.status == "succeeded") {
      callBack();
    } else {
      setIsLoading(false);
      setMessage("unexpected state.");
    }
  }
  return (
    <form className="payment_form" onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        className="pay_btn"
        style={{ backgroundColor: "#4780e4" }}
        disabled={isLoading}
      >
        {isLoading ? <LoadingSpinner /> : `Pay Now $${amount}`}
        <span className="card_error_message">{message}</span>
      </button>
    </form>
  );
}

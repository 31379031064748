import React, { useEffect, useState } from "react";
import "./home.css";
import "animate.css";
import WhyUsContainer from "./WhyUsContainer";
import Offers from "./Offers";
import bookingImg from "../../Assets/employethumbsup.png";
import { highGlass } from "../../Assets";
import BookingSteps from "./BookingSteps";
import ModalComp from "../../Componants/modal/ModalComp";
import QAComp from "../../Componants/QAComp/QAComp";
// import BlurContactForm from "../../Componants/contactFormComp/BlurContactForm";
import { ErrorCode } from "../../Constants/constantVariable";
import LoadingSpinner from "../../Componants/LoadingSpinner/LoadingSpinner";
import NoDataView from "../../Componants/NoDataView/NoDataView";
import { getAllFaqs } from "../../Api/faq";
import Card from "../Services/Card";
import { getServiceCards } from "../../Api/service";
import { Enums } from "../../Constants/Enums";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import slider_1 from "../../Assets/slide-1.jpg";
import slider_2 from "../../Assets/slide-2.jpg";
import slider_3 from "../../Assets/slide-3.jpg";
import ReasonToChosseUs from "../../Componants/ReasonToChooseUs/ReasonToChosseUs";
import Carousel from "../../Componants/ReviewCarousal";
import Slider from "react-slick";
import OverallReviews from "../OverAllReviews/OverallReviews";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Intro from "../../Componants/Intro/Intro";
import NaturalCleaning from "../../Componants/NaturalCleaning/NaturalCleaning";
export default function HomePage() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrMsg, setIsErrMsg] = useState("");
  const [faqData, setFaqData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [discount_info, setDiscount_info] = useState({
    property_discount: "",
    other_discount: "",
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slider_1, slider_2, slider_3];
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000); // Change slide every 5 seconds (5000 milliseconds)

    return () => clearInterval(interval); // Cleanup function to clear interval on unmount
  }, [currentSlide]); // Dependency array ensures effect runs when currentSlide changes

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    fetchCardsData();
    fetchFaqData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fetchFaqData = () => {
    setIsLoading(true);
    getAllFaqs()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setFaqData(data.result);
        } else if (data.error_code == ErrorCode.not_exist) {
          setIsErrMsg("No Data to View Yet!");
        } else {
          setIsErrMsg("Oops! Some error occured.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsErrMsg("Network Error.");
      });
  };

  const fetchCardsData = () => {
    setIsLoading(true);
    getServiceCards()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == ErrorCode.success) {
          setCardsData(
            data.result.filter(
              (item) =>
                item.is_main == Enums.service_cards.sevices_cleaning_types
            )
          );
        } else if (data.error_code == ErrorCode.not_exist) {
          setIsErrMsg("No Data to View Yet!");
        } else {
          setIsErrMsg("Oops! Some error occured.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsErrMsg("Network Error.");
      });
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide the default arrows
  };
  return (
    <>
      {/* <ModalComp isVisible={isVisible} setIsVisible={setIsVisible} isFrom /> */}
      <ToastContainer
        autoClose={1000}
        hideProgressBar
        position="bottom-right"
      />
      <article className="home margin_top">
        <div className="carousel-container_1 pt-[64px] bg-white">
          <Slider {...settings}>
            <div className="carousel-slide_1 slide1"></div>
            <div className="carousel-slide_1 slide2"></div>
            <div className="carousel-slide_1 slide3"></div>
          </Slider>
          <div className="static-content">
            <div className="carousel-text_1  md:px-20 w-[90%] md:w-[70%] m-auto">
              <div className="flex justify-center items-center flex-col gap-3 sm:gap-3">
                {" "}
                <h1 className="text-lg xs:text-xl md:text-3xl font-bold">
                  #1 Cleaning Company in Perth
                </h1>
                {/* <p className="text-xs  md:text-sm">
                  Your trusted partner for all your cleaning needs. We offer
                  top-quality cleaning services for homes and businesses, making
                  your space shine and sparkle.
                </p> */}
                <div
                  className="book_now_btn"
                  onClick={() => navigate("/quotation")}
                >
                  Book now
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* services cards  */}

        <div className="w-full hidden md:block">
          <h2 className="home_services_heading tex-2xl md:text-4xl">
            Our Services
          </h2>
          <div className="cards_container" style={{ paddingBottom: "0px" }}>
            {isLoading ? (
              <LoadingSpinner />
            ) : cardsData?.length == 0 ? (
              <NoDataView msg={isErrMsg} size={1.6} />
            ) : (
              cardsData?.map((item) => (
                <Card
                  // icon={item?.icon_number}
                  heading={item?.heading}
                  description={item?.description}
                  bgImg={item.image}
                />
              ))
            )}
          </div>
        </div>
        {/* In small screen  */}
        <div className="services_main_line_container1 block md:hidden">
          <h2 className="home_services_heading text-2xl md:text-4xl">
            Our Services
          </h2>
          <div className="cards_container">
            {isLoading ? (
              <LoadingSpinner />
            ) : cardsData?.length === 0 ? (
              <NoDataView msg={isErrMsg} size={1.6} />
            ) : (
              <Slider {...settings1}>
                {cardsData?.map((item, index) => (
                  <div key={index} className="px-1">
                    <div className="w-full flex justify-center">
                      <Card
                        icon={item?.icon}
                        heading={item?.heading}
                        description={item?.description}
                        bgImg={item?.image}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>

        {/* intro about Us */}
        <Intro />

        {/* Reson to choose Us */}
        <ReasonToChosseUs />

        {/* Review Carousal */}
        <Carousel />

        {/* Overall Reviews  */}
        <OverallReviews />

        {/* offer areas  */}
        {/* <Offers /> */}

        {/* offer areas  */}
        <NaturalCleaning />

        <div className="home_faq_container">
          <strong>FAQ</strong>
          {isLoading ? (
            <LoadingSpinner />
          ) : faqData.length == 0 ? (
            <NoDataView msg={isErrMsg} size={1.4} />
          ) : (
            faqData.map((data, index) => (
              <QAComp
                index={index + 1}
                question={data?.question}
                awnser={data?.answer}
              />
            ))
          )}
        </div>
      </article>
    </>
  );
}

import React from "react";

export default function OurStory({
  img,
  heading,
  description,
  bg,
  isContentbg,
}) {
  return (
    <div
      className="bottom_div_booking about"
      style={{ backgroundColor: bg ? bg : "", minHeight: bg ? "75vh" : "" }}
    >
      <div className="bottom_div_booking_pic center">
        <img src={img} alt="bi" />
      </div>
      <div
        className="bottom_div_booking_content about"
        style={{
          backgroundColor: isContentbg ? "white" : "",
        }}
      >
        {bg ? null : (
          <strong className="bottom_div_booking_content_title_line about">
            Our Story
          </strong>
        )}
        <div
          className="bottom_div_booking_content_title_sub_line"
          style={{ color: bg ? "#5a5a5a" : "" }}
        >
          {heading}
        </div>
        <p style={{ color: bg ? "black" : "" }}>{description}</p>
        <p style={{ color: bg ? "black" : "" }}>
          {
            "We've got you covered across various industries. Our professionalism and safety are at the core of our reputation, backed by ongoing training to handle your unique requirements."
          }
        </p>
        {/* <button
          className="bottom_div_booking_content__btn"
          // onClick={() => {
          //   OnClick();
          // }}
        >
          Contact Us
        </button> */}
      </div>
    </div>
  );
}

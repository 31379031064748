import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GiBrickWall } from "react-icons/gi";
import { PRIMARY } from "../../Constants/Colors";

export default function ExtraComp({
  Icon = GiBrickWall,
  image = null,
  name = "Balcony",
  quantity = 6,
  text = "Additional Balcony",
  selectQuestion = "how many balcony you need to clean?",
  isNotSelect = false,
  value = "",
  onSelect = () => {},
  onIsCheckedValue = () => {},
}) {
  let [isChecked, setIsChecked] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (value) {
      setIsChecked((isChecked = true));
    }
    name === "misc" ? createMiscOptions() : createOptions();
  }, []);

  function createOptions() {
    let arr = [];
    for (let i = 1; i <= quantity; i++) {
      const element = { value: i, label: `${i} ${name}` };
      arr.push(element);
    }
    setOptions((prev) => arr);
  }
  function createMiscOptions() {
    let arr = [];
    for (let i = 50; i <= 1000; i += 50) {
      const element = { value: i, label: `$${i}` };
      arr.push(element);
    }
    setOptions((prev) => arr);
  }

  return (
    <div className="extra">
      {image ? (
        <img src={image} alt="img" />
      ) : (
        <span className="extra_icon">
          {<Icon size={32} fill={"#711919"} />}
        </span>
      )}
      <span className="extra_text">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            onIsCheckedValue(e.target.checked);
            if (!e.target.checked && !isNotSelect) onSelect(null);
          }}
        />
        <span>{text}</span>
      </span>

      <span
        className="extra_select"
        style={{
          height: isChecked ? "" : 0,
          overflow: isChecked ? "visible" : "",
        }}
      >
        {isNotSelect ? null : (
          <>
            <span>{selectQuestion}</span>
            <Select
              placeholder="Select"
              options={options}
              value={value}
              onChange={(value) => onSelect(value)}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: "transparent",
                  //   backgroundColor: state.isFocused ? "white" : "#F5F6FA",
                  //   width: "310px",
                  border: `1px solid lightgray`,
                  borderColor: `lightgray`,
                  borderRadius: "8px",
                  fontSize: "14px",
                  padding: "0px 13px",
                }),
              }}
            />
          </>
        )}
      </span>
    </div>
  );
}

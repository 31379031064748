import { apiFormDataInstance, apiInstance } from "./ApiMiddleware";

export const getAllReviews = () => {
  const api = apiInstance();
  return api.get("api/review/get-all");
};

export const postReview = (data) => {
  const api = apiFormDataInstance();
  return api.post("api/review/post", data);
};

import React from "react";
import Select from "react-select";
import { PiBuildings } from "react-icons/pi";
import { PRIMARY } from "../../Constants/Colors";

export default function PropertyInfo({
  Icon = PiBuildings,
  placeholder = "Select",
  question = "How many Storey?",
  value = null,
  options = [{ value: 1, label: "1 Bedroom" }],
  onSelect = () => {},
}) {
  return (
    <div className="property_info_main">
      <div className="property_info_content">
        <span className="property_info_icon">
          <Icon size={28} fill={PRIMARY} />
        </span>
        <span className="property_info_question">{question}</span>
      </div>
      <div className="property_info_select">
        <Select
          placeholder={placeholder}
          defaultValue={value}
          onChange={(value) => onSelect(value)}
          options={options}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: "transparent",
              //   backgroundColor: state.isFocused ? "white" : "#F5F6FA",
              //   width: "310px",
              border: `1px solid ${PRIMARY}`,
              borderColor: `${PRIMARY}`,
              borderRadius: "8px",
              fontSize: "1.1rem",
              padding: "0px 13px",
            }),
          }}
        />
      </div>
    </div>
  );
}

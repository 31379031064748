import React, { useEffect, useState } from "react";
import "./Footer.css";
import { SlSocialFacebook, SlSocialInstagram } from "react-icons/sl";
import { FaTiktok } from "react-icons/fa";
import logo from "../../Assets/logo1.png";
import { MdEmail, MdOutlinePhoneInTalk } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { getServiceCards } from "../../Api/service";
import { ErrorCode } from "../../Constants/constantVariable";
import { Enums } from "../../Constants/Enums";
import { IoMdCheckmark } from "react-icons/io";
import { BsClock } from "react-icons/bs";
import { PRIMARY } from "../../Constants/Colors";

export default function Footer({
  fbLink = "https://www.facebook.com/profile.php?id=61559127372686&mibextid=LQQJ4d",
  instaLink = "https://www.instagram.com/awspropertycleaningservices?igsh=dWs3ajE2bmlzbnU4",
  tiktokLink = "https://www.tiktok.com/@aws.property.clea?_t=8mcjH5QBzBC&_r=1",
  phone = "08 6244 2297",
  email = "Info@awscleaningservices.com.au",
  address = "151 a birkett bedford",
}) {
  const [cardsData, setCardsData] = useState([]);
  useEffect(() => {
    fetchCardsData();
  }, []);

  const fetchCardsData = () => {
    getServiceCards()
      .then(({ data }) => {
        if (data.error_code == ErrorCode.success) {
          setCardsData(
            data.result.filter(
              (item) =>
                item.is_main == Enums.service_cards.sevices_cleaning_types
            )
          );
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  return (
    <footer>
      <div className="small_screen_style flex flex-col w-full md:w-[33%]">
        <div className="w-[70%] flex flex-col gap-2">
          <div className="footer_logo_main">
            <div className="footer_logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="text-sm py-2">
              &copy; {moment().format("YYYY")} AWS Property Cleaning Services.
              All Rights Reserved
            </div>
          </div>
          <div className="footer_icons_small_screen flex gap-4 pt-2">
            <a target="_blank" href={fbLink}>
              <SlSocialFacebook size={27} />
            </a>
            <a target="_blank" href={instaLink}>
              <SlSocialInstagram size={27} />
            </a>
            <a target="_blank" href={tiktokLink}>
              <FaTiktok size={26} />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="footer_icons">
        <a target="_blank" href={fbLink}>
          <SlSocialFacebook size={27} />
        </a>
        <a target="_blank" href={instaLink}>
          <SlSocialInstagram size={27} />
        </a>
        <a target="_blank" href={tiktokLink}>
          <FaTiktok size={26} />
        </a>
      </div> */}

      <div className="w-full md:w-[67%] flex flex-col sm:flex-row items-start">
        <div className="footer_contact_info links flex flex-col w-full sm:w-[50%]">
          <NavLink
            to="/blogs"
            className="text-[#4B5B68] font-extrabold text-lg"
          >
            Blogs & Services
          </NavLink>
          <div>
            {cardsData.length > 0 &&
              cardsData.map(
                (item, index) =>
                  index < 6 && (
                    <NavLink
                      to={"/blogs"}
                      className="footer_services nav_link_footer"
                    >
                      <IoMdCheckmark size={18} color={PRIMARY} />
                      <div>{item?.heading}</div>
                    </NavLink>
                  )
              )}
          </div>
        </div>

        <div className="footer_contact_info flex flex-col w-full sm:w-[50%]">
          <NavLink className="text-[#4B5B68] font-extrabold text-lg">
            Contact
          </NavLink>
          <div>
            <a href="tel:0862442297">
              <MdOutlinePhoneInTalk size={22} color={PRIMARY} />
              <span>{phone}</span>
            </a>
            <a href="mailto:Info@awscleaningservices.com.au">
              <MdEmail size={22} color={PRIMARY} />
              <span>{email}</span>
            </a>
            <span>
              <IoLocationOutline size={22} color={PRIMARY} />
              <span>{address}</span>
            </span>
            <span style={{ marginTop: "15px" }}>
              <BsClock size={18} color={PRIMARY} />
              <div
                className="flex flex-col"
                style={{ marginLeft: "18px", fontSize: ".9rem" }}
              >
                <div>Mon - Fri: 9:00 am - 5:00 pm</div>
                <div>Sat-Sun: 11:00 am - 4:00 pm</div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React, { useState } from "react";
import introBg from "../../Assets/introBg.jpg";
import { TiTick } from "react-icons/ti";
import introPerson from "../../Assets/IntroPerson.png";

function Intro() {
  const [activeTab, setActiveTab] = useState("provide"); // State to track active tab

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div
      style={{ background: `url(${introBg})` }}
      className="relative w-full bg-white px-10 flex flex-col md:flex-row justify-center items-center md:items-start pt-10 "
    >
      <div className="w-full md:w-[70%] z-20 min-h-[500px]">
        <div className="w-full">
          <h1 className="text-3xl md:text-5xl text-[#4b5b68] font-extrabold w-full">
            About Our Company
          </h1>
          <div className="w-full flex py-3">
            <span
              onClick={() => handleTabClick("provide")}
              className={`cursor-pointer  px-2 md:pr-10  text-[#3D4C58] text-sm md:text-xl font-semibold ${
                activeTab === "provide" ? " text-[#3FC471]" : ""
              }`}
            >
              We Provide
            </span>
            <span
              onClick={() => handleTabClick("how")}
              className={`cursor-pointer px-2  md:px-5  border-x border-gray-800 text-[#3D4C58] text-sm md:text-xl font-semibold ${
                activeTab === "how" ? " text-[#3FC471]" : ""
              }`}
            >
              How it Works
            </span>
            <span
              onClick={() => handleTabClick("with")}
              className={`cursor-pointer px-2  md:px-5  text-[#3D4C58] text-sm md:text-xl font-semibold ${
                activeTab === "with" ? " text-[#3FC471]" : ""
              }`}
            >
              With Us
            </span>
          </div>
        </div>
        <div className="w-full">
          {/* Content based on active tab */}
          {activeTab === "provide" && (
            <div className="mt-3 text-[#3D4C58] text-lg">
              <p className="w-full text-xs md:text-sm text-[#677D96]">
                Cleaning can be a chore and we know you have many choices when
                you consider hiring a maid service. Because of that, we are
                constantly thriving to improve our already high standards to
                have you see us as the absolute best in the industry. It’s not
                enough to have trust in the cleaning crew that you let into your
                home… you also have to trust that they will perform a
                first-class cleaning job for you.
              </p>
              <h1 className="text-2xl text-[#4b5b68] font-extrabold w-full pt-2 md:pt-5">
                We Provide
              </h1>
              <div className="grid grid-cols-12 justify-center w-full gap-3 py-2 md:py-5">
                <div className="flex gap-1 md:gap-2 md:col-span-6 col-span-12">
                  <TiTick className="text-[#3FC471]" />{" "}
                  <p className="text-[#677D96] text-xs md:text-sm">
                    Flexible scheduling to suit your needs
                  </p>
                </div>
                <div className="flex gap-1 md:gap-2 md:col-span-6 col-span-12">
                  <TiTick className="text-[#3FC471]" />{" "}
                  <p className="text-[#677D96] text-xs md:text-sm">
                    Pet-friendly services, Online booking and payment
                  </p>
                </div>
                <div className="flex gap-1 md:gap-2 md:col-span-6 col-span-12">
                  <TiTick className="text-[#3FC471]" />{" "}
                  <p className="text-[#677D96] text-xs md:text-sm">
                    Competitive pricing with no hidden fees
                  </p>
                </div>
                <div className="flex gap-1 md:gap-2 md:col-span-6 col-span-12">
                  <TiTick className="text-[#3FC471]" />{" "}
                  <p className="text-[#677D96] text-xs md:text-sm">
                    All cleaning materials and equipment
                  </p>
                </div>
                <div className="flex gap-1 md:gap-2 md:col-span-6 col-span-12">
                  <TiTick className="text-[#3FC471]" />{" "}
                  <p className="text-[#677D96] text-xs md:text-sm">
                    Vetted & background-checked and insured cleaners
                  </p>
                </div>
                <div className="flex gap-1 md:gap-2 md:col-span-6 col-span-12">
                  <TiTick className="text-[#3FC471]" />{" "}
                  <p className="text-[#677D96] text-xs md:text-sm">
                    100% satisfaction guarantee
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeTab === "how" && (
            <div className="mt-3 text-[#3D4C58] text-lg flex gap-2 flex-col md:flex-row">
              <div className="w-full md:w-[50%]">
                <h1 className="text-xl text-[#4b5b68] font-extrabold w-full pt-2 md:pt-5">
                  How Cleaning Company Works
                </h1>
                <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                  When the weekend finally arrives, you’d much rather put your
                  feet up while a cleaning service does the work, rather than
                  spend your precious downtime on your hands and knees
                  scrubbing.
                </p>
                <p className="w-full text-xs md:text-sm text-[#677D96] pb-2 md:pb-5">
                  When the weekend finally arrives, you’d much rather put your
                  feet up while a cleaning service does the work, rather than
                  spend your precious downtime on your hands and knees
                  scrubbing.
                </p>
              </div>
              <div className="w-full md:w-[50%]">
                <div className="w-full">
                  {" "}
                  <h1 className="text-[16px] text-[#4b5b68] font-extrabold w-full pt-2 md:pt-5">
                    Book online in{" "}
                    <span className="text-[#3FC471]">60 seconds</span>
                  </h1>
                  <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                    Book & pay online. We’ll match you with a trusted,
                    experienced house cleaner
                  </p>
                </div>
                <div className="w-full">
                  {" "}
                  <h1 className="text-[16px] text-[#4b5b68] font-extrabold w-full pt-2 md:pt-5">
                    Get a <span className="text-[#3FC471]">5 star cleaner</span>
                  </h1>
                  <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                    Every cleaner is friendly and reliable. They’ve been
                    background-checked & rated 5-stars
                  </p>
                </div>
                <div className="w-full">
                  {" "}
                  <h1 className="text-[16px] text-[#4b5b68] font-extrabold w-full pt-2 md:pt-5">
                    Manage everything{" "}
                    <span className="text-[#3FC471]">online</span>
                  </h1>
                  <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                    Add visits, skip visits, leave notes, and book extra
                    services laundry and oven cleaning
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeTab === "with" && (
            <div className="mt-3 text-[#3D4C58] text-lg">
              <div className="w-full flex flex-col lg:flex-row gap-3">
                <div className="w-full lg:w-[50%] flex gap-3 ">
                  {" "}
                  <div className="text-[#3FC471] text-2xl font-extrabold">
                    1.
                  </div>
                  <div>
                    <h1 className="text-xl text-[#4b5b68] font-extrabold w-full">
                      We Treat Your Homes Like Ours
                    </h1>
                    <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                      At The Cleaning Company, we are fully bonded and insured,
                      meaning you can have peace of mind when we enter your
                      home.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-[50%] flex gap-3">
                  {" "}
                  <div className="text-[#3FC471] text-2xl font-extrabold">
                    2.
                  </div>
                  <div>
                    <h1 className="text-xl text-[#4b5b68] font-extrabold w-full">
                      Satisfaction Guaranteed
                    </h1>
                    <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                      Our cleaning crew are professionally trained, and if
                      you’re ever unhappy with any area we’ve cleaned, we’ll and
                      reclean it next day.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col lg:flex-row gap-3 lg:pt-5">
                <div className="w-full lg:w-[50%] flex gap-3 ">
                  {" "}
                  <div className="text-[#3FC471] text-2xl font-extrabold">
                    3.
                  </div>
                  <div>
                    <h1 className="text-xl text-[#4b5b68] font-extrabold w-full">
                      Immediate Online Quotes
                    </h1>
                    <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                      Upcoming party? Expecting guests? We’ll give an immediate
                      price quote so you can enjoy your time rather than worry
                      about the mess.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-[50%] flex gap-3">
                  {" "}
                  <div className="text-[#3FC471] text-2xl font-extrabold">
                    4.
                  </div>
                  <div>
                    <h1 className="text-xl text-[#4b5b68] font-extrabold w-full">
                      We Are Experts
                    </h1>
                    <p className="w-full text-xs md:text-sm text-[#677D96] py-3">
                      We are dominate the industry in scale and scope with an
                      adaptable, extensive network that consistently delivers
                      exceptional results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full md:w-[30%]"></div>
      <div className="w-full  md:absolute left-0 bottom-0 z-10 flex justify-center md:justify-end">
        <img className="w-[500px]" src={introPerson} alt="" />
      </div>
    </div>
  );
}

export default Intro;

import React from "react";
import { useNavigate } from "react-router-dom";

function Cards({ name, img, content }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/quotation")}
      className="w-full md:w-[300px] lg:w-[33%] flex flex-col justify-center items-center px-4 gap-3 pb-10 cursor-pointer"
    >
      <div>
        <img className="w-64 h-64 rounded-full" src={img} alt={name} />
      </div>
      <div className="text-xl font-bold text-[#4b5b68] text-center">{name}</div>
      <div className="text-[#677D8F] text-sm text-center px-3 line-clamp-3">
        {content}
      </div>
    </div>
  );
}

export default Cards;
